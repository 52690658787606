import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function MediaCard({ image, title, value, imageSize }) {
  const theme = useTheme();
  return (
    <Card sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between', 
        height: '100%',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.005)',
          boxShadow: '15px 15px 30px rgba(0, 0, 0, 1)',
        },
      }}>
      <CardMedia
        component="img"
        image={image}
        title="Image title"
        sx={{ 
          objectFit: 'contain', 
          flexGrow: 0.8, // Make the image smaller to ensure text fits within the box
          maxHeight: imageSize || '60%', // Use imageSize prop if provided, else default to '60%'
          backgroundColor: theme.palette.background.default 
        }}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {`${title}: ${value}`}
        </Typography>
      </CardContent>
    </Card>
  );
}
