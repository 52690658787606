import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSidebar } from '../components/layout/SidebarContext';
import { usePostHog } from 'posthog-js/react';

function FairPlay() {
  const posthog = usePostHog();
  posthog.capture('$pageview', {
        path: window.location.pathname
    });

  const { isSidebarOpen } = useSidebar();
  const theme = useTheme();

  return (
    <Box
      sx={{
        marginLeft: isSidebarOpen ? theme.sidebar.openWidth : theme.sidebar.closedWidth,
        maxHeight: `calc(100vh - ${theme.TopBar.height})`,
        padding: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        justifyContent: 'center',
      }}
    >
      <Paper elevation={3} sx={{ p: 5 }}>
        <Typography variant="h3" gutterBottom>
          Fair Play Policy
        </Typography>
        <Typography variant="h6" gutterBottom>
          ChessBets upholds a strict Fair Play Policy to ensure a clean and fair platform for chess enthusiasts worldwide. Adherence to the following rules is mandatory for all players:
        </Typography>
        <Typography variant="body1" gutterBottom align="left" sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}>
          Players must only play their own moves without external assistance.
        </Typography>
        <Typography variant="body1" gutterBottom align="left" sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}>
          The use of chess engines, software, bots, plugins, browser extensions, or any tools that analyze positions during play is strictly prohibited. Our system is capable of detecting the use of such programs.
        </Typography>
        <Typography variant="body1" gutterBottom align="left" sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}>
          Tablebases or any resources that suggest the best move are not allowed in any chess match.
        </Typography>
        <Typography variant="body1" gutterBottom align="left" sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}>
          Automated analysis or "blunder checking" of games in progress is forbidden.
        </Typography>
        <Typography variant="body1" gutterBottom align="left" sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}>
          Account sharing or using another person's account is prohibited.
        </Typography>
        <Typography variant="body1" gutterBottom align="left" sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}>
          Artificial manipulation of ratings, matches, or game outcomes is not permitted.
        </Typography>
        <Typography variant="body1" gutterBottom align="left" sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}>
          Interfering with the gameplay of other members is unacceptable.
        </Typography>
        <Typography variant="body1" gutterBottom align="left" sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}>
          Suspicions of cheating must be reported to ChessBets and not used as an excuse for unfair play.
        </Typography>
        <Typography variant="body1" gutterBottom align="left" sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}>
          Failure to comply with these rules will result in account deactivation and forfeiture of any wallet balance.
        </Typography>
        <Typography variant="body1" align="left" sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}>
          ChessBets is dedicated to maintaining the integrity of our platform and encourages all players to compete with honor and respect, relying solely on their skills and knowledge of the game.
        </Typography>
      </Paper>
    </Box>
  );
}

export default FairPlay;
