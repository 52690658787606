import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { useGame } from '../../contexts/GameContext';

// userBox is a boolean to either show userDetails or opponentDetails
const AvatarBox = ({ avatarSrc, userBox }) => {
  
  const { userDetails, opponentDetails } = useGame();
  const details = userBox ? userDetails : opponentDetails;
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row', // Changed from 'row' to 'column'
        alignItems: 'flex-start',
        justifyContent: 'flex-start', // Changed to align to the left
        gap: 1,
        p: 2,
        backgroundColor: 'background.default',
        borderRadius: '10px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        margin: 2,
        width: '100%', // Set width to 100% to make it longer
      }}
    >
      <Avatar
        alt={details.username}
        src={avatarSrc}
        sx={{ width: '50px', height: '50px'}} // Set min width and height to 30x30
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%', pr: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Typography variant="h6" component="div">
            {details.username}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            ELO: {details.elo}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pr: 2 }}>
          <Typography variant="body2" color="text.secondary">
            Games Played: {details.gamesPlayed}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Nationality: {details.nationality}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AvatarBox;
