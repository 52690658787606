import React, { createContext, useContext } from 'react';
import { Drawer, ListItem, ListItemIcon, ListItemText, Fade, Box, Stack, Typography, Badge } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoginIcon from '@mui/icons-material/Login';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useNavigate } from 'react-router-dom';
import { BorderClear, NightsStay as MoonIcon, WbSunny as SunIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';


import SidebarBio from './SidebarBio';
import { useSidebar } from './SidebarContext';
import playIcon from '../../assets/images/playIcon.svg';
import socialIcon from '../../assets/Icons/people-holding-hands-dark-skin-tone-svgrepo-com.svg';
import profileIcon from '../../assets/Icons/account-customize-man-svgrepo-com.svg';
import statsIcon from '../../assets/Icons/stats-analytics-svgrepo-com.svg';
import challengeIcon from '../../assets/Icons/fight-svgrepo-com.svg';
import lockIcon from '../../assets/Icons/lock-part-2-svgrepo-com.svg';
import tournamentIcon from '../../assets/Icons/stadium-svgrepo-com.svg';
import legalIcon from '../../assets/Icons/gavel-svgrepo-com.svg';
import fairplayIcon from '../../assets/Icons/scales-scale-svgrepo-com.svg';
import leaderboardIcon from '../../assets/Icons/podium-svgrepo-com.svg';
import CustomIcon from '../common/CustomIcon';
import settingsIcon from '../../assets/Icons/settings-svgrepo-com.svg';
import moneyIcon from '../../assets/images/dollar-sign-circle-svgrepo-com.svg';

function Sidebar({ handleLogout, toggleTheme, themeMode }) {
  const { isSidebarOpen, toggleSidebar } = useSidebar();
  const navigate = useNavigate();
  const theme = useTheme();

  const iconSize = isSidebarOpen ? theme.sidebar.openIconSize : theme.sidebar.closedIconSize;

  const underConstruction = ["Stats", "Social", "Leaderboard", "Tournament", "Challenges", "Settings"];

  const isUnderConstruction = (text) => underConstruction.includes(text);

  const menuItems = () => [
    {
      group: 'Me',
      items: [
        {
          icon: <CustomIcon src={profileIcon} alt="Profile" size={iconSize} />,
          text: 'Profile',
          action: () => navigate('/profile'),
        },

        {
          icon: <Badge badgeContent="🚧" color="secondary" invisible={!isUnderConstruction("Social")}> <CustomIcon src={socialIcon} alt="Social" size={iconSize} /></Badge>,
          text: `Friends`,
          action: () => navigate('/friends'),
        },

        {
          icon: <Badge badgeContent="🚧" color="secondary" invisible={!isUnderConstruction("Stats")}><CustomIcon src={statsIcon} alt="Stats" size={iconSize} /></Badge>,
          text: `Stats`,
          action: () => navigate('/stats'),
        },
        {
          icon: <Badge badgeContent="🚧" color="secondary" invisible={!isUnderConstruction("Leaderboard")}><CustomIcon src={leaderboardIcon} alt="Leaderboard" size={iconSize} /></Badge>,
          text: `Leaderboard`,
          action: () => navigate('/leaderboard'),
        },
        {
          icon: <CustomIcon src={moneyIcon} alt="Leaderboard" size={iconSize} />,
          text: 'Wallet',
          action: () => navigate('/wallet'),
        },
      ],
    },

    {
      group: 'Play',
      items: [
        {
          icon: <CustomIcon src={playIcon} alt="Play" size={iconSize} />,
          text: 'Chess',
          action: () => navigate('/play'),
        },

        {
          icon: <Badge badgeContent="🚧" color="secondary" invisible={!isUnderConstruction("Tournament")}><CustomIcon src={tournamentIcon} alt="Tournament" size={iconSize} /></Badge>,
          text: `Tournament`,
          action: () => navigate('/Tournaments'),
        },
        {
          icon: <Badge badgeContent="🚧" color="secondary" invisible={!isUnderConstruction("Challenges")}><CustomIcon src={challengeIcon} alt="Challenges" size={iconSize} /></Badge>,
          text: `Challenges`,
          action: () => navigate('/challenges'),
        },
      ],
    },

    {
      group: 'Settings',
      items: [
        {
          icon: <Badge badgeContent="🚧" color="secondary" invisible={!isUnderConstruction("Settings")}><CustomIcon src={settingsIcon} alt="Settings" size={iconSize} /></Badge>,
          text: `Settings`,
          action: () => navigate('/settings'),
        },
        {
          icon: <CustomIcon src={fairplayIcon} alt="Fair play" size={iconSize} />,
          text: 'Fair play',
          action: () => navigate('/fairplay'),
        },

        {
          icon: <CustomIcon src={legalIcon} alt="Legal" size={iconSize} />,
          text: 'Legal',
          action: () => navigate('/legal'),
        },
      ],
    },
  ];
  return (
    <Drawer
      variant="permanent"
      sx={{
        zIndex: theme.sidebar.zIndex,
        maxHeight: '100vh',
        width: isSidebarOpen ? theme.sidebar.openWidth : theme.sidebar.closedWidth,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxHeight: '100vh',
          width: isSidebarOpen ? theme.sidebar.openWidth : theme.sidebar.closedWidth,
          opacity: theme.sidebar.opacity,
        }}
      >
        <ListItem button onClick={toggleSidebar} sx={{ display: 'flex', justifyContent: 'center' }}>
          <ListItemIcon sx={{ display: 'flex', justifyContent: 'center' }}>
            <MenuIcon />
          </ListItemIcon>
        </ListItem>
      </Box>

      <Fade in={true} timeout={300} key={isSidebarOpen ? 'open' : 'closed'}>
        <Stack
          direction={'column'}
          spacing={2}
          margin={0}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: isSidebarOpen ? theme.sidebar.openWidth : theme.sidebar.closedWidth,
            height: '100%',
            overflowY: 'auto',
            maxHeight: '100%',
            scrollbarWidth: 'none' /* For Firefox */,
            '&::-webkit-scrollbar': {
              width: '0px' /* For Chrome, Safari, and Opera */,
            },
          }}
        >
          {isSidebarOpen ? <SidebarBio /> : null}
          {menuItems().map((menuGroup, index) => (
            <React.Fragment key={index}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  bgcolor: theme.palette.background.default,
                  borderRadius: '10px',
                  width: '90%',
                  alignItems: 'center',
                }}
              >
                {isSidebarOpen && (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      borderRadius: '10px',
                      padding: '7px',
                      borderBottom: `1px solid ${theme.palette.background.paper}`,
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        borderColor: theme.palette.background.default,
                        backgroundColor: 'theme.palette.background.default',
                      }}
                    >
                      {menuGroup.group}
                    </Typography>
                  </Box>
                )}

                {menuGroup.items.map((items, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={items.action}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexShrink: 1,
                    }}
                  >
                    <ListItemIcon sx={{ display: 'flex', flexShrink: 1, justifyContent: 'center', width: '50%' }}>
                      {items.icon}
                    </ListItemIcon>
                    {isSidebarOpen && (
                      <ListItemText
                        sx={{ display: 'flex', flexShrink: 1, justifyContent: 'left', width: '50%' }}
                        primary={items.text}
                      />
                    )}
                  </ListItem>
                ))}
              </Box>
            </React.Fragment>
          ))}
        </Stack>
      </Fade>
    </Drawer>
  );
}

export default Sidebar;
