// matchmakingService.js
import { useContext } from 'react';
import { useWebSocket } from './WebSocketProvider'; // Adjust the import path as necessary

export function useMatchmakingService() {
  const { createWebSocket, sendMessage, closeWebSocket } = useWebSocket();

  // Function to start matchmaking process
  const startMatchmaking = (
    token,
    maxEloDifference,
    timeControl,
    betSize,
    onMatchFound,
    onSearchUpdate,
    stopSearch
  ) => {
    const wsUrl = `${process.env.REACT_APP_WS_URL}/ws/matchmaking/?token=${token}&max_elo_difference=${maxEloDifference}&time_control=${timeControl}&bet_size=${betSize}`;
    const wsKey = 'matchmaking';

    // Define onMessage handler to process WebSocket messages from the backend
    const onMessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Matchmaking update:', data);

      // Call the appropriate callback based on the message content
      if (data.error !== null) {
        alert('Error: ' + data.error);
        stopSearch();
        return;
      }
      if (data.action == 'start') {
        onMatchFound(data);
      } else {
        onSearchUpdate(data);
      }
    };

    // Create WebSocket connection with the matchmaking server
    createWebSocket(wsKey, wsUrl, onMessage);
  };

  // Function to send a custom message to the matchmaking server (if needed)
  const sendMatchmakingMessage = (message) => {
    const wsKey = 'matchmaking';
    sendMessage(wsKey, JSON.stringify(message));
  };

  // Function to stop the matchmaking process and disconnect from the server
  const stopMatchmaking = () => {
    const wsKey = 'matchmaking';
    closeWebSocket(wsKey);
  };

  return { startMatchmaking, sendMatchmakingMessage, stopMatchmaking };
}
