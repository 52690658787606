import React from 'react';
import { useState, useEffect } from 'react';

import { Card, CardContent, Typography, Box, Paper, CardActionArea, Button } from '@mui/material';
import OnlineIcon from '@mui/icons-material/OnlinePrediction'; // This can be replaced with the correct icon
import GameIcon from '@mui/icons-material/SportsEsports'; // This can be replaced with the correct icon
import PeopleIcon from '@mui/icons-material/People'; // This can be replaced with the correct icon
import VolumeOffIcon from '@mui/icons-material/VolumeOff'; // This can be replaced with the correct icon
import { styled, useTheme } from '@mui/material/styles';
import { useAuth } from '../../services/AuthContext';
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

const UserProfileCard = () => {
  const theme = useTheme();
  const navigate = useNavigate(); // Initialize useNavigate hook
  const { session } = useAuth();

  const profileIcons = [
    { icon: OnlineIcon, text: 'Online Now' },
    { icon: GameIcon, text: 'Games Played' },
    { icon: PeopleIcon, text: 'Friends' },
    { icon: VolumeOffIcon, text: 'Muted' },
  ];

  const IconsBar = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    width: '100%', // Ensure icons take up the entire width
  }));

  const IconWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& svg': {
      fontSize: '40px',
      color: theme.palette.primary.main,
    },
    '& p': {
      marginTop: theme.spacing(1),
    },
  }));

  const [userBalance, setUserBalance] = useState(0);

  useEffect(() => {

    
    const getUserBalance = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_user_balance?token=${session.access_token}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const { balance } = await response.json();
        setUserBalance(balance);
      } catch (error) {
        setUserBalance(0);
      }
    };
    if (session != null) {
        getUserBalance();
    }
  }, [session]);

  return (
    <Card sx={{ m: 'auto', boxShadow: 3, borderRadius: 2, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}> 
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}> 
        <Box sx={{ 
            flexGrow: 1, 
            border: 2, 
            borderRadius: 2, 
            borderColor: 'primary.main', 
            borderStyle: 'dashed', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            p: 2,
        }}>
            <Typography variant="h2" component="span" sx={{ fontSize: '4rem' }}>
              <Box component="span" sx={{}}>$</Box>
              <CountUp start={0} end={userBalance} duration={2.75} separator="," decimals={0} />
            </Typography>
        </Box>
      </CardContent>

      <CardActionArea sx={{ 
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
        justifyContent: 'center', 
        width: '100%', 
        height: '35%',
        background: theme.palette.background.default,
      }} onClick={() => navigate('/wallet')}>
      <Typography variant="h6" sx={{ fontSize: '1rem', color: 'primary.main' }}>
        Navigate to wallet
      </Typography>

      </CardActionArea>
    </Card>
  );
};

export default UserProfileCard;
