import React, { useState, useEffect, useContext } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, CircularProgress, Box } from '@mui/material';
import { getUserGames } from '../../services/UserApi'; // Adjust the import path as necessary
import { useAuth } from '../../services/AuthContext';
import { GameContext } from '../../contexts/GameContext'; // Import GameContext

const GameExplorer = () => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true); // Added state to track loading status
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Adjust number of rows per page as necessary
  const { session } = useAuth(); // Replace this with the actual auth token retrieval logic
  const { setGameId } = useContext(GameContext); // Use setGameId from GameContext

  useEffect(() => {
    const fetchGames = async () => {
      try {
        setLoading(true); // Start loading
        const fetchedGames = await getUserGames(session.access_token);
        setGames(fetchedGames);
      } catch (error) {
        console.error('Failed to fetch games:', error);
      } finally {
        setLoading(false); // End loading regardless of outcome
      }
    };

    fetchGames();
  }, []); // Empty dependency array means this effect runs once on mount

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (gameId) => {
    setGameId(gameId); // Set the gameId in the context when a row is clicked
  };

  return (
    <>
      <TableContainer component={Paper}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="300px">
            <CircularProgress />
          </Box>
        ) : (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Game ID</TableCell>
                <TableCell align="right">Start Time</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">White User ID</TableCell>
                <TableCell align="right">Black User ID</TableCell>
                <TableCell align="right">Time Control (seconds)</TableCell>
                <TableCell align="right">Winner</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {games.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((game) => (
                <TableRow
                  key={game.game_id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  hover
                  onClick={() => handleRowClick(game.game_id)} // Make row clickable and call handleRowClick with the game's ID
                  style={{ cursor: 'pointer' }} // Change cursor to pointer on hover to indicate clickability
                >
                  <TableCell component="th" scope="row">
                    {game.game_id}
                  </TableCell>
                  <TableCell align="right">{game.start_time}</TableCell>
                  <TableCell align="right">{game.status}</TableCell>
                  <TableCell align="right">{game.white_user_id}</TableCell>
                  <TableCell align="right">{game.black_user_id}</TableCell>
                  <TableCell align="right">{game.time_control}</TableCell>
                  <TableCell align="right">{game.winner || 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {!loading && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={games.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default GameExplorer;
