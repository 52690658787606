import React from 'react';
import PropTypes from 'prop-types';


const CustomIcon = ({ src, alt, style, fit, size }) => {
  const dynamicStyle = fit === 'width' ? { width: size, height: 'auto' } : { height: size, width: 'auto' };
  return <img src={src} alt={alt} style={{ ...style, ...dynamicStyle }} />;
};

CustomIcon.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  style: PropTypes.object,
  fit: PropTypes.oneOf(['width', 'height']),
  size: PropTypes.string,
};

CustomIcon.defaultProps = {
  alt: '',
  style: {},
  fit: 'width',
  size: '50px',
};

export default CustomIcon;




