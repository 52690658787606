import React, { useState } from 'react';
import { Box, Typography, Paper, TextField, Button, useTheme } from '@mui/material';
import { useGame } from '../../contexts/GameContext';
import { useChessGameService } from '../../services/gameService';

const LocalChat = () => {
  const theme = useTheme();

  const [newMessage, setNewMessage] = useState('');
  const { gameId, chatMessages } = useGame();
  const { sendChatMessageWebsocket } = useChessGameService();

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (newMessage.trim() !== '') {
      sendChatMessageWebsocket(newMessage, gameId);
      setNewMessage('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Box sx={{ width: '100%', maxHeight: '75%', overflow: 'scroll' }}>
        {chatMessages.map((message, index) => (
          <Paper key={index} elevation={3} sx={{ mb: 2, p: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', bgcolor: 'background.default' }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
              {message.sender}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {message.text}
            </Typography>
          </Paper>
        ))}
      </Box>
      <Box component="form" onSubmit={handleSendMessage} sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type your message"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          sx={{ mr: 1 }}
          onKeyDown={handleKeyPress}
        />
        <Button type="submit" variant="contained" color="primary">
          Send
        </Button>
      </Box>
    </Paper>
  );
};

export default LocalChat;
