import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useGame } from '../../contexts/GameContext';

const ChessTimer = ({ userOrOpponent }) => {
  const theme = useTheme();
  const { currentTurn, boardOrientation, gameId, userTime, opponentTime, fen} = useGame();
  
  // Determine initial time based on userOrOpponent flag
  const initialTime = userOrOpponent ? userTime : opponentTime;
  const [time, setTime] = useState(initialTime);

  const color = userOrOpponent ? boardOrientation : (boardOrientation === 'white' ? 'black' : 'white');

  useEffect(() => {
    let interval = null;
    console.log(fen, gameId, currentTurn, color, time);
    if (gameId && currentTurn === color && time > 0 && fen != 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1')  {
      interval = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [currentTurn, color, gameId]);

  // Sync the timer with userTime or opponentTime whenever they change
  useEffect(() => {
    if (userOrOpponent) {
      setTime(userTime);
    } else {
      setTime(opponentTime);
    }
  }, [userTime, opponentTime, userOrOpponent]);

  return (
    <Typography
      sx={{
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.h6.fontSize,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        textAlign: 'center',
      }}
    >
      {Math.floor(time / 60)}:{String(time % 60).padStart(2, '0')}
    </Typography>
  );
};

export default ChessTimer;
