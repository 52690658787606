import React, { useEffect, useState } from 'react';
import { Box, Avatar, Typography, Paper, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import pepe from "../../assets/images/pepe.jpeg";
import { useAuth } from '../../services/AuthContext';
import {getUserInfo} from '../../services/UserApi';

const test_info = () => [
    {name: "Name", value: "First Last"},
    {name: "Age", value: "21+"},
    {name: "Nationality", value: "Global Citizen"},
    {name: "ELO", value: "2000"},
];

function SidebarBio({handleLogout, toggleTheme, themeMode }) {
  const theme = useTheme();
  const { session } = useAuth();
  const [userInfo, setUserInfo] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (session) {
        try {
          const data = await getUserInfo(session.access_token);
          setUserInfo([
            {name: "Name", value: data.username},
            {name: "Age", value: data.age},
            {name: "Nationality", value: data.nationality},
            {name: "ELO", value: data.elo_rating},
          ]);
        } catch (error) {
          console.error("Failed to fetch user info:", error);
          setUserInfo(test_info());
        }
      } else {
        setUserInfo(test_info());
      }
    };

    fetchData();
  }, [session]);

  return (
    <Box
      sx={{zIndex: theme.sidebar.zIndex, display: 'flex', flexDirection: 'column', bgcolor: theme.palette.background.default, borderRadius: "10px", width: "90%" }}>
      <Avatar
        src={pepe}
        sx={{ width: 100, height: 100, margin: "auto", marginTop: "20px" }}
      />
      <Grid container spacing={1} sx={{padding: "10px", textAlign: "center"}}>
        {userInfo.map((info, index) => (
          <React.Fragment key={index}>
            <Grid item xs={6}>
              <Paper elevation={4} sx={{bgcolor: theme.palette.background.default, padding: "5px"}}>
                <Typography variant="body2">
                  {info.name}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                {info.value}
              </Typography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
}

export default SidebarBio;
