import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSidebar } from '../components/layout/SidebarContext';
import Grid from '@mui/material/Unstable_Grid2'; 
import { useTheme } from '@mui/material/styles';
import ProfileBio from '../components/common/ProfileBio';
import { Item } from '@mui/material';
import LiveGamesTable from '../components/common/LiveGamesTable';
import ProfileWallet from '../components/common/ProfileWallet';
import { useAuth } from '../services/AuthContext'; 
import { usePostHog } from 'posthog-js/react';

const Profile = () => {
  const posthog = usePostHog();
  posthog.capture('$pageview', {
        path: window.location.pathname
    });

  const { isSidebarOpen, toggleSidebar } = useSidebar();
  const theme = useTheme();
  const {session} = useAuth();
  const [userId, setUserId] = useState(session?.user?.id);

  useEffect(() => {
    setUserId(session?.user?.id);
  }, [session]);

  return (
    <div
      style={{
        marginLeft: isSidebarOpen ? theme.sidebar.openWidth : theme.sidebar.closedWidth,
        transition: 'margin-left 0.3s ease-in-out',
      }}
    >
      <Box
        sx={{
          height: '100vh',
          marginRight: theme.PagePaperMargins.margin,
          marginLeft: theme.PagePaperMargins.margin,
          flexGrow: 1,
          flexShrink: 1,
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <ProfileBio></ProfileBio>
          </Grid>
          <Grid item xs={4}>
            <ProfileWallet amount={1000}></ProfileWallet>
          </Grid>
          <Grid item xs={12}>
            <LiveGamesTable userId={userId}></LiveGamesTable>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Profile;
