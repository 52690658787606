import React, { useEffect, useState, useCallback } from 'react';
import { Chessboard } from 'react-chessboard';
import { useChessGameService } from '../../services/gameService';
import { useGame } from '../../contexts/GameContext';
import { useAuth } from '../../services/AuthContext';
import { useTheme } from '@mui/material/styles';
const PlayChess = ({ customLightSquareStyle, customDarkSquareStyle }) => {
  const {
    gameId,
    boardOrientation,
    fen,
    currentTurn,
    liveGame
  } = useGame();
  const { session } = useAuth();
  const { startGame, makeMove, endGame } = useChessGameService();
  const  theme = useTheme();

  const isDraggablePiece = ({ piece }) => {
    const isWhitePiece = piece.startsWith('w');
    const isBlackPiece = piece.startsWith('b');
    return (
      currentTurn === boardOrientation &&
      ((currentTurn === 'white' && isWhitePiece) || (currentTurn === 'black' && isBlackPiece))
    );
  };

  useEffect(() => {
    if (!gameId || !session || !liveGame) return;
    startGame(gameId);
    return () => endGame(gameId);
  }, [gameId, session]);

  const handleMove = (sourceSquare, targetSquare) => {
    const move = { from: sourceSquare, to: targetSquare };
    const moveMade = makeMove(gameId, move);
    if (!moveMade) return false;
    return true;
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Chessboard
        id="PlayChessboard"
        animationDuration={200}
        position={fen}
        boardOrientation={boardOrientation}
        customDarkSquareStyle={{ backgroundColor: customDarkSquareStyle }}
        customLightSquareStyle={{ backgroundColor: customLightSquareStyle }}
        arePiecesDraggable={gameId}
        onPieceDrop={(sourceSquare, targetSquare) => handleMove(sourceSquare, targetSquare)}
        isDraggablePiece={isDraggablePiece}
      />
    </div>
  );
};

export default PlayChess;
