import React from 'react';
import { Typography, Box, Paper, Button, Stack, Container, IconButton, Avatar, Grid } from '@mui/material';
import { useSidebar } from '../components/layout/SidebarContext';
import { useTheme } from '@mui/material/styles';
import { Chessboard } from 'react-chessboard';
import AutoChess from '../components/common/AutoChess';
import CountUp from 'react-countup';
import KeyValueContainer from '../components/common/KeyValueContainer';
import { height, unstable_createStyleFunctionSx } from '@mui/system';
import LiveGamesTable from '../components/common/LiveGamesTable';
import { Chatbox, ChatboxProvider } from '../components/common/Chatbox';
import NiceButton from '../components/common/NiceButton';
import playIcon from "../assets/Icons/playIcon.svg"
import CustomIcon from '../components/common/CustomIcon';
import AvatarBox from '../components/common/AvatarBox';
import MediaCard from '../components/common/MediaCard';
import fireIcon from "..//assets/images/wk.png"
import trophyIcon from "..//assets/images/knight.png"
import moneyIcon from "..//assets/images/br.png"
import ProfileBio from "../components/common/ProfileBio"
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { navigate } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';

function Home() {
  const posthog = usePostHog();
  posthog.capture('$pageview', {
        path: window.location.pathname
    });

  const {isSidebarOpen, toggleSidebar } = useSidebar();
  const theme = useTheme();
  const navigate = useNavigate(); // Correctly initialized useNavigate hook

  return (
    <div style={{ marginLeft: isSidebarOpen ? theme.sidebar.openWidth : theme.sidebar.closedWidth, transition: 'margin-left 0.3s ease-in-out' }}>
    <Box sx={{ marginRight: theme.PagePaperMargins.margin ,marginLeft: theme.PagePaperMargins.margin, flexGrow: 1, flexShrink: 1, p: 3, display: 'flex', flexDirection: 'column', gap: 2, justifyContent: "center" }}>


  <Grid container columnSpacing={"5%"}>
  <Grid item xs={12} md={5}>
  <AutoChess autoPlay={true} lightSquareColor={theme.defaultBoard.lightSquare} darkSquareColor={theme.defaultBoard.darkSquare} />
  </Grid>




  <Grid item xs={12} md= {7}>
            <Stack direction="column" spacing={2} alignContent={"center"} justifyContent={"space-between"} sx={{height: "100%", display:  "flex"}}>
            
            <Stack direction="column" spacing={1} justifyContent={"space-around"}>
            <Typography variant="h2" component="h1" gutterBottom fontWeight={"Bold"} color={"Background.paper"} >
              Wager on the
            </Typography>
            <Typography variant="h2" component="h1" gutterBottom fontWeight={"Bold"} color={"Background.paper"}>
            #1 Chess Betting
            </Typography>
            <Typography variant="h2" component="h1" gutterBottom fontWeight={"Bold"} color={"Background.paper"}>
             Platform
            </Typography>
            </Stack>


            <Stack direction="row" spacing={2} justifyContent= {"space-around"}>
              <MediaCard image={trophyIcon} title="Players" value="100" />
              <MediaCard image={moneyIcon} title="Total Wagers" value="100" />
              <MediaCard image={fireIcon} title="Games" value="100" />
            </Stack>

            <Stack direction="row" spacing={2} justifyContent={"space-around"}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => navigate('/play')} // Correctly implemented onClick event to navigate to the play page
              sx={{ 
                display: "flex", 
                alignItems: "center",
                justifyContent: "center",
                fontSize: 'calc(1rem + 1vw)', // Dynamically calculated font size
                padding: 'max(15px, 1.5vw) max(20px, 1.5vw)', 
                borderRadius: '15px', 
                margin: '8px 0', 
                flexGrow: 1,
                boxShadow: theme.shadows[1],
                background: `linear-gradient(160deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.005)',
                  boxShadow: '15px 15px 30px rgba(0, 0, 0, 1)',
                },
                textTransform: "none",
                color: 'white', // Set button text color to white
              }}
            >
              Play Now
            </Button>

            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => navigate("/wallet")}
              sx={{ 
                display: "flex", 
                alignItems: "center",
                justifyContent: "center",
                fontSize: 'calc(1rem + 1vw)', // Dynamically calculated font size
                padding: 'max(15px, 1.5vw) max(20px, 1.5vw)', 
                borderRadius: '15px', 
                margin: '8px 0', 
                flexGrow: 1,
                boxShadow: theme.shadows[1],
                background: `linear-gradient(160deg, ${theme.palette.secondary.light}, ${theme.palette.secondary.dark})`,
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.005)',
                  boxShadow: '15px 15px 30px rgba(0, 0, 0, 1)',
                },
                textTransform: "none",
              }}
            >
              Deposit Funds
            </Button>
            </Stack>
             </Stack>

  </Grid>
  <Grid item xs={12}>
    <LiveGamesTable ></LiveGamesTable>
  </Grid>

  </Grid>
 </Box>
     </div>
  );
}

export default Home;