
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as LogoSVG } from '../../assets/images/decoded_image.svg'; // Import ChessBetsLogo.svg

const ChessBetsLogo = () => {
  const theme = useTheme();
  return (
    <LogoSVG 
      style={{ 
        width: theme.spacing(10), 
        display: 'flex', 
        alignItems: 'center',
        height: 'auto', 
        fill: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black 
      }} 
    />
  );
};

export default ChessBetsLogo;

