import React, { useCallback, useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Select, MenuItem } from '@mui/material';
import { useMatchmakingService } from '../../services/MatchmakingService'; // Adjust import paths as needed
import { useAuth } from '../../services/AuthContext'; // Adjust import paths as needed
import { useGame } from '../../contexts/GameContext.js';

import { usePostHog } from 'posthog-js/react';

function MatchmakingTab() {
  const posthog = usePostHog();
  const [timeControl, setTimeControl] = useState(3);
  const [betSize, setBetSize] = useState(0);
  const [maxEloDifference, setMaxEloDifference] = useState(100);
  const { startMatchmaking, stopMatchmaking } = useMatchmakingService();
  const { session } = useAuth();
  const [hasCheckedUserInGame, setHasCheckedUserInGame] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const {
    gameId,
    setGameId,
    setUserDetails,
    setOpponentDetails,
    setBoardOrientation,
    setFen,
    setCurrentTurn,
    setUserTime,
    setOpponentTime,
    resetGame,
    setLiveGame,
    liveGame,
  } = useGame();

  const startSearch = useCallback(() => {
    setIsSearching(true);
    if (session && session.access_token) {
      posthog.capture('matchmaking_start', {
        user_id: session?.user?.id,
        time_control: timeControl,
        bet_size: betSize,
        max_elo_difference: maxEloDifference,
      });
      startMatchmaking(
        session.access_token,
        maxEloDifference,
        timeControl,
        betSize,
        handleMatchFound,
        handleSearchUpdate,
        stopSearch
      );
    } else {
      console.error('Session or access token is undefined');
      setIsSearching(false);
    }
  }, [session, startMatchmaking, timeControl, betSize, maxEloDifference, setIsSearching]);

  const stopSearch = useCallback(() => {
    posthog.capture('matchmaking_stop', {
      user_id: session?.user?.id,
    });
    stopMatchmaking();
    setIsSearching(false);
  }, [stopMatchmaking, setIsSearching]);

  // Placeholder functions for handling matchmaking events
  const handleMatchFound = useCallback(
    (data) => {
      console.log('Handle Match Found: ', data);
      setIsSearching(false);
      setLiveGame(true);
      resetGame();

      if (data.user_statuses && data.user_details) {
        const userDetails = data.user_details[session.user.id];
        const opponentId = Object.keys(data.user_details).find((id) => id !== session.user.id);
        const opponentDetails = data.user_details[opponentId];

        setUserTime(data.user_statuses[session.user.id].time_left);
        setOpponentTime(data.user_statuses[opponentId].time_left);

        setUserDetails({
          username: userDetails.username,
          elo: userDetails.elo_rating,
          gamesPlayed: userDetails.number_of_games_played,
          nationality: userDetails.nationality,
        });

        setOpponentDetails({
          username: opponentDetails.username,
          elo: opponentDetails.elo_rating,
          gamesPlayed: opponentDetails.number_of_games_played,
          nationality: opponentDetails.nationality,
        });
      }
      if (data.fen) {
        setFen(data.fen);
      }
      if (data.whose_turn) {
        setCurrentTurn(data.whose_turn.toLowerCase());
      }

      setBoardOrientation(data.user_statuses[session.user.id].color.toLowerCase());
      setGameId(data.game_id);
    },
    [session, setGameId, setUserDetails, setOpponentDetails, setBoardOrientation]
  );

  const handleSearchUpdate = useCallback((data) => {
    console.log('Search update:', data.message);
  }, []);

  useEffect(() => {
    const checkUserInGame = async () => {
      try {
        if (session && session.access_token && !hasCheckedUserInGame) {
          console.log('Checking if user is already in a game', session);
          setHasCheckedUserInGame(true);
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/check_user_already_in_game?token=` + session.access_token
          );
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          console.log('Reconnect Packet: ', data);
          if (data) {
            handleMatchFound(data);
          }
        }
      } catch (error) {
        console.error('Error checking if user is already in a game:', error);
      }
    };

    if (!hasCheckedUserInGame) {
      checkUserInGame();
    }
  }, [session, hasCheckedUserInGame]); // Depend on session and hasCheckedUserInGame

  useEffect(() => {
    if (gameId !== '0') {
      setIsSearching(false);
    }
  }, [gameId]);

  return (
    <Box sx={{ padding: 3 }}>
      <Select value={timeControl} onChange={(e) => setTimeControl(e.target.value)} fullWidth>
        <MenuItem value={3}>3 min</MenuItem>
        <MenuItem value={5}>5 min</MenuItem>
      </Select>
      <Select value={betSize} onChange={(e) => setBetSize(e.target.value)} fullWidth sx={{ mt: 2 }}>
        <MenuItem value={0}>$0</MenuItem>
        <MenuItem value={1}>$1</MenuItem>
      </Select>
      <Select value={maxEloDifference} onChange={(e) => setMaxEloDifference(e.target.value)} fullWidth sx={{ mt: 2 }}>
        <MenuItem value={100}>elo +/-100</MenuItem>
        <MenuItem value={250}>elo +/-250</MenuItem>
        <MenuItem value={500}>elo +/-500</MenuItem>
      </Select>
      {isSearching ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress />
          <Button variant="contained" color="secondary" fullWidth onClick={stopSearch} sx={{ ml: 2 }}>
            Cancel Search
          </Button>
        </Box>
      ) : (
        <Button variant="contained" color="primary" fullWidth onClick={startSearch} sx={{ mt: 2 }}>
          Play
        </Button>
      )}
    </Box>
  );
}

export default MatchmakingTab;
