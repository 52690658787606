import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  padding: '20px 24px',
  fontSize: '1.8rem',
  borderRadius: '20px',
  margin: '10px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  '&:active': {
    top: '2px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  },
  '& img': {
    marginRight: '10px',
    width: '100px', // Adjusted width to a fixed size to ensure visibility
    height: '100px', // Adjusted height to match the width for consistency
    objectFit: 'contain', // Ensures the image is scaled correctly within the fixed dimensions
  },
}));

const NiceButton = ({ iconSrc, children, ...props }) => {
  return (
    <CustomButton {...props}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        {iconSrc && <img src={iconSrc} alt="" style={{ alignSelf: 'center' }} />}
        <Box sx={{ flexGrow: 1 }}>
          {React.Children.map(children, (child, index) => (
            <Typography key={index} component="span" display="block">
              {child}
            </Typography>
          ))}
        </Box>
      </Box>
    </CustomButton>
  );
};

export default NiceButton;
