import React, { createContext, useContext, useState } from 'react';
import { useAuth } from '../../services/AuthContext';
import { Modal, Box, Button, TextField, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { postUserRegistration } from '../../services/UserApi';
import { usePostHog } from 'posthog-js/react';

// Create the RegisterModalContext
const RegisterModalContext = createContext();


export const useRegisterModal = () => useContext(RegisterModalContext);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const RegisterModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <RegisterModalContext.Provider value={{ handleOpen, handleClose }}>
      {children}
      <RegisterForm open={open} handleClose={handleClose} />
    </RegisterModalContext.Provider>
  );
};

const RegisterForm = ({ open, handleClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [nationality, setNationality] = useState('');
  const [eloRating, setEloRating] = useState('');

  const { register } = useAuth(); // Assuming useAuth is already set up to handle these new fields

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userInfo = {
      email, password, name, username: userName, dob: dateOfBirth, nationality, elo_rating: eloRating
    };
    register(userInfo); // Pass userInfo to the register function
    handleClose();
  };
  const posthog = usePostHog();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
          Register
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            type="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            type="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="userName"
            label="Username"
            name="userName"
            autoComplete="username"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="dateOfBirth"
            label="Date of Birth"
            name="dateOfBirth"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="nationality"
            label="Nationality"
            name="nationality"
            autoComplete="nationality"
            value={nationality}
            onChange={(e) => setNationality(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="eloRating"
            label="ELO Rating"
            name="eloRating"
            type="number"
            autoComplete="elo-rating"
            value={eloRating}
            onChange={(e) => setEloRating(e.target.value)}
          />
          <Button 
            type="submit" 
            fullWidth 
            variant="contained" 
            sx={{ mt: 3, mb: 2 }}
            onClick={() => posthog.capture('registration_complete', {
              'username': userName,
            })}
          >
            Register
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export { RegisterModalProvider, RegisterForm };
