import React, { useState, useEffect } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
  TablePagination,
} from '@mui/material';
import { AccessTime, Person, SportsEsports, MonetizationOn, Flag } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

// Assuming getRecentGames is your API call function
import { getRecentGames } from '../../services/GamesApi'; // Update the import path as needed

function LiveGamesTable({ userId = null }) {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true); // Added state for loading
  const [tabValue, setTabValue] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    const fetchGames = async () => {
      try {
        setLoading(true); // Start loading
        const data = await getRecentGames(userId); // Directly await the function
        setGames(data); // Store all games without slicing
        setLoading(false); // End loading
      } catch (error) {
        console.error('Failed to fetch games:', error);
        setLoading(false); // Ensure loading is false on error
      }
    };

    fetchGames();
  }, [userId]); // Fetch once on component mount

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0); // Reset to first page when rows per page changes
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        p: 2,
        flexGrow: 1,
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="live games tabs">
          <Tab label="Recent Games" />
          {/* Placeholder for future tabs */}
          <Tab label="Upcoming Games" disabled />
        </Tabs>
      </Box>
      <TableContainer component={Paper} sx={{ maxHeight: 'auto', backgroundColor: theme.palette.background.paper }}>
        <Table stickyHeader aria-label="recent games table">
          <TableHead>
            <TableRow>
              <TableCell>
                <AccessTime />
              </TableCell>
              <TableCell>
                <Person /> White
              </TableCell>
              <TableCell>
                <Person /> Black
              </TableCell>
              <TableCell>
                <SportsEsports /> Winner
              </TableCell>
              <TableCell>
                <Flag /> Reason
              </TableCell>
              <TableCell>
                <AccessTime /> Time Control (s)
              </TableCell>
              <TableCell>
                <MonetizationOn /> Bet Size
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={7} sx={{ textAlign: 'center' }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {games.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((game, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {new Date(game.start_time).toLocaleString()}
                  </TableCell>
                  <TableCell>{game.white_username}</TableCell>
                  <TableCell>{game.black_username}</TableCell>
                  <TableCell>
                    {game.winner === game.white_user_id ? game.white_username : game.black_username}
                  </TableCell>
                  <TableCell>{game.reason}</TableCell>
                  <TableCell>{game.time_control}</TableCell>
                  <TableCell>${game.bet_size}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {!loading && (
        <TablePagination
          component="div"
          count={games.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
      {/* Placeholder for future tab content */}
      {tabValue === 1 && (
        <Typography variant="body1" sx={{ mt: 2 }}>
          Upcoming games will be listed here.
        </Typography>
      )}
    </Box>
  );
}

export default LiveGamesTable;
