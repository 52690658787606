import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Avatar, Box, Paper } from '@mui/material';
import OnlineIcon from '@mui/icons-material/OnlinePrediction'; // This can be replaced with the correct icon
import GameIcon from '@mui/icons-material/SportsEsports'; // This can be replaced with the correct icon
import PeopleIcon from '@mui/icons-material/People'; // This can be replaced with the correct icon
import VolumeOffIcon from '@mui/icons-material/VolumeOff'; // This can be replaced with the correct icon
import pepe from '../../assets/images/pepe.jpeg';
import { styled } from '@mui/material/styles';
import { getUserInfo } from '../../services/UserApi';
import { useAuth } from '../../services/AuthContext';

const UserProfileCard = () => {
  const { session } = useAuth();
  const profileIcons = [
    { icon: OnlineIcon, text: 'Online Now' },
    { icon: GameIcon, text: 'Games Played' },
    { icon: PeopleIcon, text: 'Friends' },
    { icon: VolumeOffIcon, text: 'Muted' },
  ];
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const user = await getUserInfo(session.access_token);
        setUserInfo({ ...user, email: session.user.email, join_date: session.user.created_at.split('T')[0] });
        console.log(userInfo);
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };
    if (session) {
      fetchUserInfo();
    }
  }, [session]);

  const IconsBar = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    width: '100%', // Ensure icons take up the entire width
  }));

  const IconWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& svg': {
      fontSize: '40px',
      color: theme.palette.primary.main,
    },
    '& p': {
      marginTop: theme.spacing(1),
    },
  }));

  const KeyValueWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0.5),
  }));

  const KeyPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    marginRight: theme.spacing(1),
    boxShadow: theme.shadows[2],
  }));

  return (
    <Card sx={{ m: 'auto', boxShadow: 3, borderRadius: 2 }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
        <Avatar src={pepe} sx={{ width: 128, height: 128, border: '4px solid white', marginRight: 2 }} />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <KeyValueWrapper>
                <KeyPaper><Typography variant="h6"><strong>Username:</strong></Typography></KeyPaper>
                <Typography variant="h6">{userInfo?.username}</Typography>
              </KeyValueWrapper>
              <KeyValueWrapper>
                <KeyPaper><Typography variant="h6"><strong>Email:</strong></Typography></KeyPaper>
                <Typography variant="h6">{userInfo?.email}</Typography>
              </KeyValueWrapper>
              <KeyValueWrapper>
                <KeyPaper><Typography variant="h6"><strong>Date of Birth:</strong></Typography></KeyPaper>
                <Typography variant="h6">{userInfo?.dob}</Typography>
              </KeyValueWrapper>
            </Grid>
            <Grid item xs={6}>
              <KeyValueWrapper>
                <KeyPaper><Typography variant="h6"><strong>Join Date:</strong></Typography></KeyPaper>
                <Typography variant="h6">{userInfo?.join_date}</Typography>
              </KeyValueWrapper>
              <KeyValueWrapper>
                <KeyPaper><Typography variant="h6"><strong>Nationality:</strong></Typography></KeyPaper>
                <Typography variant="h6">{userInfo?.nationality}</Typography>
              </KeyValueWrapper>
              <KeyValueWrapper>
                <KeyPaper><Typography variant="h6"><strong>ELO Rating:</strong></Typography></KeyPaper>
                <Typography variant="h6">{userInfo?.elo_rating}</Typography>
              </KeyValueWrapper>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
      <IconsBar>
        {profileIcons.map(({ icon: Icon, text }, index) => (
          <IconWrapper key={index}>
            <Icon />
            <Typography variant="body2">{text}</Typography>
          </IconWrapper>
        ))}
      </IconsBar>
    </Card>
  );
};

export default UserProfileCard;
