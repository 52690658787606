import React from 'react';
import { Box, Typography } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import { useTheme } from '@mui/material/styles';
import { useSidebar } from '../components/layout/SidebarContext';
import { usePostHog } from 'posthog-js/react';

function UnderConstructionPage() {
  const theme = useTheme();
  const posthog = usePostHog();
  posthog.capture('$pageview', {
        path: window.location.pathname
    });

  const { isSidebarOpen } = useSidebar();

  return (
    <Box
      sx={{
        marginLeft: isSidebarOpen ? theme.sidebar.openWidth : theme.sidebar.closedWidth,
        maxHeight: `calc(100vh - ${theme.TopBar.height})`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        overflow: 'hidden',
        bgcolor: theme.palette.background.default,
      }}
    >
      <ConstructionIcon sx={{ fontSize: 100, color: theme.palette.primary.main }} />
      <Typography variant="h4" sx={{ mt: 2, color: theme.palette.text.primary }}>
        Under Construction
      </Typography>
      <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary }}>
        We're working on it. Check back soon!
      </Typography>
    </Box>
  );
}

export default UnderConstructionPage;
