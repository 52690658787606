import React, { useContext, useEffect, useState } from 'react';
import { useGame } from '../../contexts/GameContext';




const EndGamePopup = () => {
  const { gameEnd, resetGame } = useGame();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (gameEnd?.winner !== '') {
      console.log('Game over:', gameEnd);
      setShowPopup(true);
    }
  }, [gameEnd]);

  const handleClose = () => {
    setShowPopup(false);
    resetGame();
  };

  return (
    <>
      {showPopup && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#f0f0f0',
          padding: '30px',
          zIndex: 1000,
          borderRadius: '15px',
          boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
          width: '400px',
          textAlign: 'center',
        }}>
          <h2 style={{ color: '#333', marginBottom: '20px' }}>Game Over</h2>
          <p style={{ fontSize: '18px', color: '#555' }}><strong>Winner:</strong> {gameEnd?.winner}</p>
          <p style={{ fontSize: '18px', color: '#555' }}><strong>Reason:</strong> {gameEnd?.reason}</p>
          <p style={{ fontSize: '18px', color: '#555' }}><strong>New ELO:</strong> {gameEnd?.elo}</p>
          <p style={{ fontSize: '18px', color: '#555' }}><strong>New Balance:</strong> {gameEnd?.balance}</p>
          <button onClick={handleClose} style={{
            marginTop: '25px',
            padding: '12px 25px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '8px',
            cursor: 'pointer',
            fontSize: '16px',
            fontWeight: 'bold',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          }}>
            Close
          </button>
        </div>
      )}
    </>
  );
};

export default EndGamePopup;
