import * as React from 'react';
import { Tabs, Tab, Box, Button, Typography, CircularProgress, Select, MenuItem } from '@mui/material';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import GamesIcon from '@mui/icons-material/Games';
import ChatIcon from '@mui/icons-material/Chat';
import SearchIcon from '@mui/icons-material/Search'; // Added for Viewer tab icon
import { useTheme } from '@mui/material/styles';
import { useMatchmakingService } from '../../services/MatchmakingService';
import { useAuth } from '../../services/AuthContext';
import { useGame } from '../../contexts/GameContext.js';
import { useCallback, useState, useEffect } from 'react';
import GameExplorer from './GameExplorer.js';
import GameViewer from './GameViewer.js';
import LocalChat from './LocalChat.js';
import MatchmakingTab from './MatchmakingTab.js';


export default function GameTabs() {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const {
    gameId,
    liveGame
  } = useGame();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabStyle = (isActive) => ({
    bgcolor: isActive ? theme.palette.background.default : theme.palette.background.paper,
    color: isActive ? theme.palette.text.primary : theme.palette.text.secondary,
  });

  useEffect(() => {
    if (liveGame) {
      setValue(3); // Set default tab to Viewer when live game is true
    }
  }, [liveGame]);

  useEffect(() => {
    if (gameId !== '') {
      setValue(3); // Automatically switch to the Viewer tab when gameId changes and is not '0'
    }
  }, [gameId]); // Depend on gameId to switch to the Viewer tab automatically



  return (
    <Box sx={{ width: '100%', height: '100%', bgcolor: theme.palette.background.paper, display: 'flex', flexDirection: 'column' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        centered
        variant="fullWidth"
        sx={{ bgcolor: theme.palette.background.default }}
      >
        <Tab icon={<SportsEsportsIcon />} label="New Game" sx={tabStyle(value === 0)} disabled={liveGame} />
        <Tab icon={<GamesIcon />} label="Games" sx={tabStyle(value === 1)} disabled={liveGame} />
        <Tab icon={<ChatIcon />} label="Chat" sx={tabStyle(value === 2)} disabled={!liveGame} />
        <Tab icon={<SearchIcon />} label="Viewer" sx={tabStyle(value === 3)} /> {/* Added Viewer tab */}
      </Tabs>
      {value === 0 && !liveGame && (
        <Box sx={{ padding: 3, bgcolor: theme.palette.background.default, color: theme.palette.text.primary, flexGrow: 1 }}>
          <MatchmakingTab></MatchmakingTab>
          <Typography variant="h5" sx={{ marginBottom: 2, marginTop: 3}}>Game Play Rules</Typography>
          <Typography variant="body1" sx={{ marginBottom: 2, textAlign: 'left', border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}>
            1. You and your opponent each put up $ to compete.<br />
            2. The winner takes the entire pot minus a 5% rake.<br />
            3. In the event of a draw, funds are returned to your accounts, less the rake.<br />
            4. Please consult the Fair Play Rules and Terms of Service for details on cheating, detection, enforcement, and penalties.<br />
          </Typography>
        </Box>
      )}
      {value === 1 && (
        <Box sx={{ padding: 3, bgcolor: theme.palette.background.default, color: theme.palette.text.primary, flexGrow: 1 }}>
          {/* Content for Games Tab */}

          <GameExplorer></GameExplorer>
        </Box>
      )}
      {value === 2 && (
        <Box sx={{ padding: 3, bgcolor: theme.palette.background.default, color: theme.palette.text.primary, flexGrow: 1 }}>
          <LocalChat> </LocalChat>
        </Box>
      )}
      {/* Placeholder for Viewer tab content */}
      {value === 3 && (
        <Box sx={{ padding: 3, bgcolor: theme.palette.background.default, color: theme.palette.text.primary, flexGrow: 1 }}>
          <GameViewer></GameViewer>
        </Box>
      )}
    </Box>
  );
}

