import React from 'react';
import { AppBar, Toolbar, Typography, useTheme, Box, Button } from '@mui/material';
import ChessBetsLogo from '../common/ChessBetsLogo';
import WalletBalance from '../common/Wallet'; // Added import for WalletBalance
import { useSidebar } from './SidebarContext';
import { useLoginModal } from '../common/Login';
import { useRegisterModal } from '../common/Register';
import { useAuth } from '../../services/AuthContext';
import { useNavigate } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';

function TopBar() {
  const posthog = usePostHog();
  const { session, logout } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const { isSidebarOpen } = useSidebar();
  const { handleOpen: handleRegisterOpen } = useRegisterModal();
  const { handleOpen: handleLoginOpen } = useLoginModal();

  const handleLogout = async () => {
    await logout();
    navigate('/home');
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        zIndex: theme.TopBar.zIndex,
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
        height: theme.TopBar.height,
        marginLeft: isSidebarOpen ? theme.sidebar.openWidth : theme.sidebar.closedWidth,
        width: `calc(100vw - ${isSidebarOpen ? theme.sidebar.openWidth : theme.sidebar.closedWidth})`,
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 3,
        paddingRight: 3,
      }}
    >
      <Box sx={{ justifyContent: 'left', display: 'flex', alignItems: 'center', width: '100%', m: 0, p: 0 }}>
        <div onClick={() => navigate('/home')} style={{ cursor: 'pointer' }}>
          <ChessBetsLogo />
        </div>
      </Box>

      <Box sx={{ display: 'flex', direction: 'column', alignItems: 'center' }}>
        <WalletBalance /> {/* Centered Wallet Component */}
      </Box>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
        {session ? (
          <Button
            variant="contained"
            onClick={() => {
              handleLogout();
              posthog.capture('user_logout', {
                user_id: session.user.id,
              });
            }}
            sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginLeft: 1 }}
          >
            <Typography>Logout</Typography>
          </Button>
        ) : (
          <>
            <Button
              variant="text"
              onClick={() => {
                handleLoginOpen();
                posthog.capture('user_login', {});
              }}
              sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}
            >
              <Typography>Login</Typography>
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleRegisterOpen();
                posthog.capture('registration_start', {});

              }}
              sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginLeft: 1 }}
            >
              <Typography>Register</Typography>
            </Button>
          </>
        )}
      </Box>
    </AppBar>
  );
}

export default TopBar;
