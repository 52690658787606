import { loadScript } from '@paypal/paypal-js';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  useTheme,
  CircularProgress,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Stack,
  TextField,
  InputAdornment,
  List,
  ListItemText,
  ListItem,
} from '@mui/material';
import { useSidebar } from '../components/layout/SidebarContext';
import { useAuth } from '../services/AuthContext';
import { styled } from '@mui/material/styles';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LiveGamesTable from '../components/common/LiveGamesTable';
import { usePostHog } from 'posthog-js/react';
import { useNavigate } from 'react-router-dom';

function PaymentScreen() {
  const navigate = useNavigate();
  const theme = useTheme();
  const posthog = usePostHog();
  posthog.capture('$pageview', {
        path: window.location.pathname
    });

  const { isSidebarOpen } = useSidebar();
  const { session } = useAuth();
  const [clientID, setClientID] = useState(null);
  const [rendered, setRendered] = useState(false);
  const [depositAmount, setDepositAmount] = useState(5);
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [maxWithdrawalAmount, setMaxWithdrawalAmount] = useState(0);

  useEffect(() => {
    const getClientID = async () => {
        console.log("Here");
      if (clientID !== null) {
        return;
      }
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/get-paypal-details?token=${session.access_token}`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
          }
        );
        const { client_id, user } = await response.json();
        setClientID(client_id);
      } catch (error) {
        console.error('Failed to get-paypal-details', error);
      }
    };

    const getMaxWithdrawalAmount = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/get_user_balance?token=${session.access_token}`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          }
        );
        const { balance } = await response.json();
        console.log('bal resp', response);
        setMaxWithdrawalAmount(balance);
      } catch (error) {
        console.error('Failed to get-max-withdrawal', error);
        setMaxWithdrawalAmount(0);
      }
    };
    if (session != null) {
      getClientID();
      getMaxWithdrawalAmount();
    }
  }, [session]);

  useEffect(() => {
    const renderPayPalButtons = async () => {
      let paypal;
      try {
        console.log("Trying to render");
        paypal = await loadScript({ clientId: clientID });
      } catch (error) {
        console.error('failed to load the PayPal JS SDK script', error);
      }
      if (paypal) {
        try {
          // TODO: Resolve adding {fundingSource: paypal.FUNDING.VENMO} to Button args
          await paypal
            .Buttons({
              createOrder: () => {
                posthog.capture('deposit_paypal_click', {
                  user_id: session?.user?.id,
                  amount: depositAmount,
                });
                return fetch(
                  `${process.env.REACT_APP_SERVER_URL}/create_order?token=${session.access_token}&value=${depositAmount}`,
                  {
                    //
                    method: 'post',
                    headers: {
                      'content-type': 'application/json',
                    },
                  }
                )
                  .then((response) => {
                    return response.json();
                  })
                  .then((order) => {
                    // Your backend should return the order ID created on PayPal
                    return order.id;
                  });
              },
              onApprove: (data, actions) => {
                console.log(data);
                return fetch(
                  `${process.env.REACT_APP_SERVER_URL}/complete_order?token=${session.access_token}&order_id=${data.orderID}`,
                  {
                    method: 'post',
                  }
                )
                  .then((response) => {
                    return response.json();
                  })
                  .then((capture) => {
                    // Handle successful capture of payment
                    if (capture.status === 'COMPLETED') {
                      console.log('Payment completed successfully!');
                      navigate('/payment-complete');
                      // You can navigate to a success page or update the UI accordingly
                    } else {
                      // Handle any other payment status accordingly
                    }
                  });
              },
              onError: (err) => {
                // Handle errors
                console.error('Error during payment processing: ', err);
              },
            })
            .render('#paypal-deposit-button');

          setRendered(true);
        } catch (error) {
          console.error('failed to render the PayPal Buttons', error);
        }
      }
    };
    if (!rendered && clientID !== null) {
      console.log('Client ID: ', clientID);
      renderPayPalButtons();
    }
  }, [clientID]);

  const handleSetDepositAmount = (event, newAmount) => {
    setDepositAmount(newAmount);
  };

  const submitWithdrawalAmount = async (amount) => {
    console.log('submitting withdrawal amount', amount);
    posthog.capture('withdrawal_submit', {
      user_id: session?.user?.id,
      amount: amount,
    });
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/create_withdrawal?token=${session.access_token}&value=${amount}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }
    );
    if (response.ok) {
      const json = await response.json();
      console.log('json', json);
      setMaxWithdrawalAmount(json.balance);
      console.log('Withdrawal processed', json);
      setWithdrawalAmount(0);
    } else {
      const error = await response.json();
      console.error('Error processing withdrawal:', error);
      alert(error.detail);
    }
  };

  return (
    <Box
      sx={{
        height: '100%',
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        flexGrow: 1,
        flexShrink: 1,
        p: 3,
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          marginLeft: isSidebarOpen ? theme.sidebar.openWidth : theme.sidebar.closedWidth,
          transition: 'margin-left 0.3s ease-in-out',
        }}
      ></div>
      <Stack direction="column" spacing={2} sx={{ width: '70%', height: '85vh' }}>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            backgroundColor: theme.palette.background.paper,
            height: '100%',
            overflow: 'auto',
            borderRadius: 10,
          }}
        >
          <Typography variant="h4" sx={{ mb: 2 }}>
            Your History
          </Typography>
          <LiveGamesTable
            jsonData={JSON.stringify([
              { 'Game ID': 1, 'Player Name': 'Withdrawal: $50', Time: '2023-09-01' },
              { 'Game ID': 2, 'Player Name': 'Deposit: $100', Time: '2023-08-25' },
              { 'Game ID': 3, 'Player Name': 'In-game Purchase: $20', Time: '2023-08-20' },
            ])}
          />
        </Paper>
      </Stack>

      <Stack direction="column" spacing={2} sx={{ width: '30%' }}>
        {/* Deposits and Withdrawals  */}
        <Paper
          elevation={3}
          sx={{ p: 2, backgroundColor: theme.palette.background.paper, height: '100%', borderRadius: 10 }}
        >
          <Stack
            direction="column"
            spacing={2}
            sx={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}
          >
            <Typography variant="h5">Deposit Funds</Typography>
            <ToggleButtonGroup
              size="large"
              exclusive
              value={depositAmount} // Added value prop to control the selected value
              onChange={(event, newDepositAmount) => {
                handleSetDepositAmount(event, newDepositAmount);
                posthog.capture('deposit_set_amount', {
                  user_id: session?.user?.id,
                  amount: newDepositAmount,
                });
              }}
              aria-label="deposit amount"
            >
              <ToggleButton value={5} aria-label="left aligned">
                $5
              </ToggleButton>
              <ToggleButton value={10} aria-label="centered">
                $10
              </ToggleButton>
              <ToggleButton value={25} aria-label="right aligned">
                $25
              </ToggleButton>
            </ToggleButtonGroup>
            <div id="paypal-deposit-button"></div>
          </Stack>
        </Paper>

        <Paper
          elevation={3}
          sx={{ p: 2, backgroundColor: theme.palette.background.paper, height: '100%', borderRadius: 10 }}
        >
          <Stack
            direction="column"
            spacing={2}
            sx={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}
          >
            <Typography variant="h5" sx={{ mb: 2 }}>
              Withdraw Funds
            </Typography>

            {/*  UI for Withdrawal Amount Input and Balance Display */}
            <Box sx={{ mb: 2 }}>
              <TextField
                type="text"
                variant="outlined"
                value={withdrawalAmount.toString()}
                onChange={(e) => {
                  let newValue = e.target.value === '' ? '0' : e.target.value.replace(/[^0-9\.]/g, ''); // Ensure only numbers are allowed
                  if (newValue[0] === '0' && newValue.length > 1) {
                    newValue = newValue.substring(1);
                  }
                  setWithdrawalAmount(newValue);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                  style: { textAlign: 'center' }, // Center the text
                }}
                inputProps={{
                  // Remove input type="number" default browser behaviour (side arrows)
                  style: { textAlign: 'center' }, // Ensure the text is centered
                }}
                helperText={
                  <Typography
                    component="span"
                    variant="body1"
                    style={{ fontSize: '1rem', textAlign: 'center' }}
                  >{`Your Balance: $${parseFloat(maxWithdrawalAmount).toFixed(2)}`}</Typography>
                } // Center the helper text
                error={parseFloat(withdrawalAmount) > parseFloat(maxWithdrawalAmount)} // Outline in red if withdrawal amount is greater than balance
              />
            </Box>

            <Button
              variant="contained"
              onClick={() => submitWithdrawalAmount(withdrawalAmount)}
              disabled={parseFloat(withdrawalAmount) > parseFloat(maxWithdrawalAmount)} // Disable the button if withdrawal amount is greater than balance
            >
              Withdraw
            </Button>
          </Stack>
        </Paper>

        <Paper
          elevation={3}
          sx={{ p: 2, backgroundColor: theme.palette.background.paper, height: '100%', borderRadius: 10 }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Note on Fees
          </Typography>
          <Typography variant="caption" sx={{ mb: 2, fontSize: '.75rem' }}>
            We charge a small fee for deposits and withdrawals to offset payment processing fees. This amounts to $0.49
            + 4% on Deposits and a fee rate of 2% on Withdrawals. By using our service you agree to these fees. We would
            love to not charge these, but unfortunately our costs on online transactions are insanely high! Please reach
            out at chessbetsllc@gmail.com if you have any suggestions or feedback.
          </Typography>
        </Paper>
      </Stack>
    </Box>
  );
}

export default PaymentScreen;
