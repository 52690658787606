import { createClient } from '@supabase/supabase-js';

// Supabase service URL
const supabaseUrl = 'https://phcvnzimwwfvfypdbabo.supabase.co';
// Supabase service anon (public) key
const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBoY3Zuemltd3dmdmZ5cGRiYWJvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDc2MjI3NDIsImV4cCI6MjAyMzE5ODc0Mn0.914hqtyIw0qAIoLIAgp6aQdsg08tILzNd1xTStSElVU"
// Create a single Supabase client for interacting with your database 
export const supabase = createClient(supabaseUrl, supabaseAnonKey);


