import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from './supabaseClient'; // Make sure this import path is correct
import { postUserRegistration } from './UserApi'; // Import the API function for registration

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
    });

    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      console.log('Auth state changed:', _event);
      setSession(session);
      setLoading(false);
    });

    // Clean up the listener when the component unmounts
    return () => {
      //   console.log("Unsubscribing from auth listener");
      authListener.subscription.unsubscribe();
    };
  }, []);

  const login = async (email, password) => {
    console.log('Attempting login with:', email);
    try {
      setLoading(true);
      const { error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) throw error;
    } catch (error) {
      console.error('Login error:', error.error_description || error.message);
      alert(error.error_description || error.message);
    } finally {
      supabase.auth.getSession().then(({ data: { session } }) => {
        // console.log("Current session:", session);
        setSession(session);
        setLoading(false);
      });
      console.log('Session:', session);
      setLoading(false);
    }
  };

  const register = async (userInfo) => {
    console.log('Attempting registration with form data');
    try {
      setLoading(true);
      const detailsResponse = await postUserRegistration(userInfo);
      if (detailsResponse.error) throw detailsResponse.error;
      console.log('Registration successful, details added:', detailsResponse);
      // Handle post-registration logic here, e.g., redirecting the user or showing a success message
    } catch (error) {
      console.error('Registration error:', error.error_description || error.message);
      alert(error.error_description || error.message);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    console.log('Attempting logout...');
    try {
      setLoading(true);
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      console.log('Logout successful');
      setSession(null);
    } catch (error) {
      console.error('Logout error:', error.error_description || error.message);
      alert(error.error_description || error.message);
    } finally {
      setLoading(false);
    }
  };

  const value = { session, loading, login, logout, register };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
