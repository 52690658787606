import React, { createContext, useContext, useState, useEffect } from 'react';
import {getUserInfo} from '../services/UserApi';
import { useAuth } from '../services/AuthContext';

export const GameContext = createContext();
export const useGame = () => useContext(GameContext);

// Default values
const defaultGameId = '';
const defaultUserDetails = {
  username: '',
  elo: 0,
  gamesPlayed: 0,
  nationality: '',
};
const defaultFen = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';
const defaultBoardOrientation = 'white';
const defaultCurrentTurn = 'white';
const defaultOpponentDetails = {
  username: 'Opponent',
  elo: 0,
  gamesPlayed: 0,
  nationality: '',
};


const defaultGameEnd = {
  winner: '',
  reason: '',
  elo: 0,
  balance: 0,
};


export const GameProvider = ({ children }) => {
  const { session } = useAuth();
  const [gameId, setGameId] = useState(defaultGameId);
  const [userDetails, setUserDetails] = useState(defaultUserDetails);
  const [fen, setFen] = useState(defaultFen);
  const [boardOrientation, setBoardOrientation] = useState(defaultBoardOrientation);
  const [currentTurn, setCurrentTurn] = useState(defaultCurrentTurn);
  const [opponentDetails, setOpponentDetails] = useState(defaultOpponentDetails);
  const [gameEnd, setGameEnd] = useState(defaultGameEnd);
  const [userTime, setUserTime] = useState(0);
  const [opponentTime, setOpponentTime] = useState(0);
  const [liveGame, setLiveGame] = useState(false);

  const [chatMessages, setChatMessages] = useState([
    { sender: "Admin", text: "Welcome to the chat!" },
    { sender: "Admin", text: "Be nice to each other :)" }
  ]);
  
  useEffect(() => {
    const fetchUserData = async () => {
      if (session) {
        try {
          const data = await getUserInfo(session.access_token);
          setUserDetails({
            username: data.username,
            elo: data.elo,
            gamesPlayed: data.gamesPlayed,
            nationality: data.nationality,
          });
        } catch (error) {
          console.error("Failed to fetch user data:", error);
        }
      }
    };

    fetchUserData();
  }, [session]);

  const resetGame = () => {
    setGameId(defaultGameId);
    setUserDetails(defaultUserDetails);
    setFen(defaultFen);
    setBoardOrientation(defaultBoardOrientation);
    setCurrentTurn(defaultCurrentTurn);
    setOpponentDetails(defaultOpponentDetails);
    setGameEnd(defaultGameEnd);
  };

  const value = {
    gameId,
    setGameId,
    userDetails,
    setUserDetails,
    opponentDetails,
    setOpponentDetails,
    boardOrientation,
    setBoardOrientation,
    fen,
    setFen,
    currentTurn,
    setCurrentTurn,
    gameEnd,
    setGameEnd,
    resetGame,
    userTime,
    setUserTime,
    opponentTime,
    setOpponentTime,
    chatMessages,
    setChatMessages,
    liveGame,
    setLiveGame
  };

  return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
};