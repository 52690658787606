import { createTheme } from '@mui/material';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#9c27b0',
      contrastText: '#ffffff',
    },
    background: {
      default: '#f0f2f5',
      paper: '#ffffff',
      superback: '#282c34',
    },
    text: {
      primary: '#000000',
      secondary: '#757575',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    allVariants: {
      color: '#000000',
    },
  },
  custom: {
    svgColor: '#000000',
  },
  sidebar: {
    closedWidth: '20%',
    openWidth: '20%',
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#81B64C',
    },
    secondary: {
      main: '#51504D',
    },
    background: {
      default: '#262522',
      paper: '#302E2B',
      backMost: '#282c34',
    },
    text: {
      primary: '#ffffff',
      secondary: '#F8F9F9',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    allVariants: {
      color: '#ffffff',
    },
  },
  custom: {
    svgColor: '#e91e63',
  },
  sidebar: {
    closedWidth: '100px',
    openWidth: '300px',
    zIndex: 1,
    opacity: 0.9,
    closedIconSize: '50px',
    openIconSize: '25px',
  },
  TopBar: {
    zIndex: 2,
    height: '50px',
  },
  defaultBoard: {
    darkSquare: '#779556',
    lightSquare: '#EBECD0',
  },
  PagePaperMargins: {
    margin: '1%',
  },

  shadows: [
    'none',
    '10px 10px 20px rgba(0, 0, 0, 0.1)',
    '10px 10px 20px rgba(0, 0, 0, 0.2)',
    '10px 10px 20px rgba(0, 0, 0, 0.3)',
    '10px 10px 20px rgba(0, 0, 0, 0.4)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],

});

export { darkTheme, lightTheme };
