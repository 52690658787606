import React, { createContext, useContext, useState, useEffect, useRef } from 'react';

const WebSocketContext = createContext();

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
  const [webSockets, setWebSockets] = useState({});
  const webSocketsRef = useRef(webSockets);
  webSocketsRef.current = webSockets; // Keep the ref current

  useEffect(() => {
    return () => {
      Object.values(webSocketsRef.current).forEach((value) => {
        if (value && value.ws) {
          value.ws.close();
        }
      });
    };
  }, []);

  const createWebSocket = (key, url, onMessage, onClose) => {
    if (!key || !url || webSocketsRef.current[key]) return;

    const ws = new WebSocket(url);

    ws.onopen = () => {
      updateWebSocketState(key, { isConnected: true, ws });
    };

    ws.onmessage = (event) => {
      if (typeof onMessage === 'function') {
        onMessage(event);
      }
    };

    ws.onclose = () => {
      updateWebSocketState(key, null); // Remove WebSocket from state
      if (typeof onClose === 'function') {
        onClose();
      }
    };

    updateWebSocketState(key, { isConnected: false, ws });
  };

  const sendMessage = (key, message) => {
    if (!webSocketsRef.current || !webSocketsRef.current[key]) {
      console.log(`WebSocket with key ${key} does not exist.`);
      return;
    }
    const { isConnected, ws } = webSocketsRef.current[key];
    if (!isConnected || !ws) {
      console.log(`${key} WebSocket is not connected or WebSocket instance is not available.`);
      return;
    }
    ws.send(message);
  };

  const closeWebSocket = (key) => {
    const { ws } = webSocketsRef.current[key] || {};
    if (ws) {
      ws.close();
    }
  };

  // Helper function to update WebSocket state
  const updateWebSocketState = (key, newState) => {
    setWebSockets((prev) => {
      const newStateValue = newState ? { ...prev[key], ...newState } : {};
      return { ...prev, [key]: newState ? newStateValue : undefined };
    });
  };

  const value = {
    createWebSocket,
    sendMessage,
    closeWebSocket,
    webSockets: webSocketsRef.current,
  };

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};
