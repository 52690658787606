// ChessGameService.js
import React, { useState, useEffect } from 'react';
import { useContext, useCallback } from 'react';
import { Chess } from 'chess.js';
import { useWebSocket } from './WebSocketProvider'; // Adjust the import path as necessary
import { useGame } from '../contexts/GameContext';
import { useAuth } from './AuthContext';

export function useChessGameService(
) {
  //   console.log("Initializing Chess Game Service");
  const { createWebSocket, sendMessage, closeWebSocket } = useWebSocket();
  const { session} = useAuth();
  const { setLiveGame, fen, setFen, boardOrientation, setBoardOrientation, setCurrentTurn, currentTurn, GameEnd, setGameEnd, setUserTime, setOpponentTime, chatMessages, setChatMessages } = useGame();
  const [chessGame, setChessGame] = useState(new Chess());

  useEffect(() => {
    if (fen) {
      chessGame.load(fen); // This will now not be null
      const moves = chessGame.moves({ verbose: true });
      if (moves.length > 0) {
        const lastMoveColor = moves[0].color;
        setCurrentTurn(lastMoveColor === 'w' ? 'white' : 'black');
      }
    } else {
      // Handle the case where fen is not available
      console.log('FEN string is null, loading default position');
    }
  }, [fen]);


  // Function to start a new chess game
  const startGame = (gameId) => {
    console.log(`Starting game with ID: ${gameId}`);
    const wsUrl = `${process.env.REACT_APP_WS_URL}/ws/game/${gameId}?token=${session.access_token}`;
    const wsKey = `chessGame-${gameId}`;
    console.log(`WebSocket URL: ${wsUrl}`);
    setLiveGame(true);

    // Define onMessage handler to process WebSocket messages from the backend
    const onMessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Recvd Data:', data);

      // First, check if there's an error message
      if (data.error) {
        console.error('Error from server:', data.error);
        // Handle the error appropriately, maybe show it to the user
        return; // Stop further processing
      }
      
      switch (data.action) {
        case 'chat':
          console.log(data)
          console.log('Received chat message:', data.chat_message);
          if (data.chat_message) {
            console.log(data.chat_message)
            setChatMessages(chatMessages => [...chatMessages, { sender: data.chat_message.sender, text: data.chat_message.text }]);
            }
          break;
        case 'disconnect':
            console.log("opponent disconnected")
            break;

        case 'end':
            console.log("In Game Service end")
            console.log('Game Over:', data);

            if (data.outcome) {
              // Process game over information
              console.log(`Winner: ${data.outcome.winner}, Reason: ${data.outcome.reason}`);
              setGameEnd({
                winner: data.outcome.winner,
                reason: data.outcome.reason,
                elo: data.outcome.elo,
                balance: data.outcome.balance,
              });



              

              // Optionally, display a message to both players or update UI components accordingly
            }
          break;


        default:
            setFen(data.fen);
            if (data.user_statuses){
                const opponentId = Object.keys(data.user_statuses).find((id) => id !== session.user.id);
                setUserTime(data.user_statuses[session.user.id].time_left);
                setOpponentTime(data.user_statuses[opponentId].time_left);
            }
            // setGameEnd(data.game_end);
            break;
      }
    };

    // Define onClose handler to handle unexpected WebSocket disconnections
    const onClose = () => {
      console.error('WebSocket connection closed unexpectedly.');
      setLiveGame(false);
      // Handle the unexpected disconnection here, maybe try to reconnect or inform the user
    };

    // Create WebSocket connection with the chess game server
    createWebSocket(wsKey, wsUrl, onMessage, onClose);
    console.log('WebSocket connection created for game:', gameId);
  };

  // Function to validate and send a move
  const makeMove = (gameId, move) => {
    console.log(`Making move for game ID: ${gameId}`, move);
    const wsKey = `chessGame-${gameId}`;

    // Validate move locally using chess.js
    let moveResult;
    try {
      moveResult = chessGame.move(move);
    } catch (e) {
      console.log(e);
      return false;
    }
    if (moveResult === null) {
      console.error('Invalid move attempted:', move);
      return false; // Move was invalid
    } else {
      console.log('Move validated locally:', moveResult);

      console.log('Pre-move FEN: ', fen);
      // setCurrentTurn(currentTurn === 'white' ? 'black' : 'white');
      setFen(chessGame.fen());
      console.log('FEN after local move:', chessGame.fen(), currentTurn);

      

      // if (typeof onLocalMoveValidated === 'function') {
      //   onLocalMoveValidated(chessGame.fen());
      // }
    }

    // If valid, send the move to the server for further validation and processing
    sendMessage(wsKey, JSON.stringify({ action: 'move', move: moveResult }));
    console.log('Move sent to server:', moveResult);

    return true; // Move was valid and sent
  };

  const resignGame = (gameId) => {
    console.log(`Resigning game with ID: ${gameId}`);
    const wsKey = `chessGame-${gameId}`;
    sendMessage(wsKey, JSON.stringify({ action: 'resign' }));
  }

  const sendChatMessageWebsocket = (message, gameId) => {
    console.log(`Sending chat message for game ID: ${gameId}`, message);
    const wsKey = `chessGame-${gameId}`;
    sendMessage(wsKey, JSON.stringify({ action: 'chat', message: message }));
    console.log('Chat message sent to server:', message);
  };


  // Function to end the game and disconnect from the server
  const endGame = (gameId) => {
    console.log(`Ending game and disconnecting from server for game ID: ${gameId}`);
    const wsKey = `chessGame-${gameId}`;
    closeWebSocket(wsKey);
    chessGame.reset(); // Optionally reset the chess.js instance for a new game
    console.log('Game ended and WebSocket connection closed:', gameId);
  };

  return { startGame, makeMove, endGame, sendChatMessageWebsocket, resignGame };
}
