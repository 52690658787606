const getGameMoves = async (gameId) => {
    if (!gameId) {
        throw new Error('Game ID is required');
    }

    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/games/${gameId}/moves`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch game moves: ${errorText}`);
    }
    
    const moves = await response.json();
    console.log(moves);
    return moves;
};

const getRecentGames = async (userId = null) => {
    const url = userId 
        ? `${process.env.REACT_APP_SERVER_URL}/games/recent?user_id=${userId}` 
        : `${process.env.REACT_APP_SERVER_URL}/games/recent`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch recent games: ${errorText}`);
    }
    
    const recentGames = await response.json();

    return recentGames;
};

export { getGameMoves, getRecentGames };
