import React from 'react';
import Sidebar from './components/layout/Sidebar';
import TopBar from './components/layout/TopBar';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { darkTheme, lightTheme, kennyTheme } from './themes/theme';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { AuthProvider } from './services/AuthContext';
import { Chatbox, ChatboxProvider } from './components/common/Chatbox';
import Play from './pages/Play';
import PaymentScreen from './pages/Payment';

import FairPlay from './pages/FairPlay';
import Legal from './pages/Legal';
import Profile from './pages/Profile';
import UnderConstructionPage from './pages/UnderConstructionPage';

import './App.css';
import { SidebarProvider } from './components/layout/SidebarContext';
import Home from './pages/Home';
import { WebSocketProvider } from './services/WebSocketProvider';
import { LoginModalProvider } from './components/common/Login';
import { RegisterModalProvider } from './components/common/Register';

import { initGA } from './services/analytics';

function App() {
//   initGA();
  return (
    <div className="App" style={{ backgroundColor: darkTheme.palette.background.paper, minHeight: '100vh' }}>
      <ThemeProvider theme={darkTheme}>
        <AuthProvider>
          <CssBaseline />
          <ChatboxProvider>
            <Chatbox />
          </ChatboxProvider>
          <Router>
            <SidebarProvider>
              <Sidebar />
              <RegisterModalProvider>
                <LoginModalProvider>
                  <TopBar />
                </LoginModalProvider>
              </RegisterModalProvider>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/Home" element={<Home />} />
                <Route
                  path="/Play"
                  element={
                    <WebSocketProvider>
                      <Play />
                    </WebSocketProvider>
                  }
                />
                <Route path="/Wallet" element={<PaymentScreen />} />
                <Route path="/payment-complete" element={<PaymentScreen />} />
                <Route path="/Profile" element={<Profile />} />
                <Route path="/leaderboard" element={<UnderConstructionPage />} />
                <Route path="/stats" element={<UnderConstructionPage />} />
                <Route path="/friends" element={<UnderConstructionPage />} />
                <Route path="/tournaments" element={<UnderConstructionPage />} />
                <Route path="/challenges" element={<UnderConstructionPage />} />
                <Route path="/settings" element={<UnderConstructionPage />} />
                <Route path="/legal" element={<Legal />} />
                <Route path="/fairplay" element={<FairPlay />} />
              </Routes>
            </SidebarProvider>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
