import React, { useContext, useState, useEffect } from 'react';
import { useGame } from '../../contexts/GameContext'; // Adjust the import path as necessary
import { getGameMoves } from '../../services/GamesApi'; // Adjust the import path as necessary
import { Button, Box } from '@mui/material'; // Import MUI components for styling
import { useChessGameService } from '../../services/gameService'; // Adjusted the import path

const GameViewer = () => {
  const { currentTurn, liveGame, gameId, setFen } = useGame();
  const [moves, setMoves] = useState([]);
  const [currentMoveIndex, setCurrentMoveIndex] = useState(0);
  const { resignGame } = useChessGameService();

  useEffect(() => {
    const fetchMoves = async () => {
      if (gameId && !liveGame) {
        try {
          const fetchedMoves = await getGameMoves(gameId);
          setMoves(fetchedMoves);
          setCurrentMoveIndex(0); // Reset to the first move
        } catch (error) {
          console.error('Failed to fetch game moves:', error);
        }
      }
    };

    fetchMoves();
  }, [gameId]);

  const handleMoveClick = (index) => {
    setCurrentMoveIndex(index);
    setFen(moves[index].fen);
  };

  const handlePrevClick = () => {
    const newIndex = Math.max(currentMoveIndex - 1, 0);
    setCurrentMoveIndex(newIndex);
    setFen(moves[newIndex].fen);
  };

  const handleNextClick = () => {
    const newIndex = Math.min(currentMoveIndex + 1, moves.length - 1);
    setCurrentMoveIndex(newIndex);
    setFen(moves[newIndex].fen);
  };

  const handleResignClick = () => {
    resignGame(gameId);
  };

  return (
    <Box sx={{ padding: 2, bgcolor: 'background.default', color: 'text.primary', borderRadius: 1 }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 1 }}>
        {moves.map((move, index) => (
          <Button 
            key={move.move_id} 
            onClick={() => handleMoveClick(index)}
            variant="contained" 
            color="primary" 
            sx={{ mb: 1 }}
          >
            {move.move_notation}
          </Button>
        ))}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
        <Button 
          onClick={handlePrevClick} 
          disabled={currentMoveIndex === 0}
          variant="outlined" 
          color="secondary"
        >
          Prev
        </Button>
        <Button 
          onClick={handleNextClick} 
          disabled={currentMoveIndex === moves.length - 1}
          variant="outlined" 
          color="secondary"
        >
          Next
        </Button>
        {liveGame && (
          <Button 
            onClick={handleResignClick} 
            variant="contained" 
            color="error"
            sx={{ mt: 2 }}
          >
            Resign
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default GameViewer;