import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Tab,
  Tabs,
  Box,
  TextField,
} from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PropTypes from 'prop-types';
import { useAuth } from '../../services/AuthContext';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const WalletBalance = () => {
  const theme = useTheme();
  const [balance, setBalance] = useState('0');
  const [openDepositDialog, setOpenDepositDialog] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const {session} = useAuth();
  const handleOpenDepositDialog = () => {
    setOpenDepositDialog(true);
  };

  const handleCloseDepositDialog = () => {
    setOpenDepositDialog(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const navigate = useNavigate();

  useEffect(() => {
    const getUserBalance = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_user_balance?token=${session.access_token}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const { balance } = await response.json();
        setBalance(balance);
      } catch (error) {
        setBalance(0);
      }
    };
    if (session != null) {
        getUserBalance();
    }
  }, [session]);

  return (
    <div style={{ display: 'flex', direction:'column', alignItems: 'center', justifyContent: 'center' }}>
      <Typography variant="body1" style={{ marginRight: '10px' }}>
        ${balance} 
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/wallet')}
        style={{
          background: `linear-gradient(160deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
          color: 'white',
        }}
      >
        Wallet
      </Button>

      {/* Deposit and Withdraw Dialog */}
      <Dialog open={openDepositDialog} onClose={handleCloseDepositDialog}>
        <DialogTitle>Wallet</DialogTitle>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="wallet tabs">
          <Tab label="Deposit" {...a11yProps(0)} />
          <Tab label="Withdraw" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <DialogContent>
            {/* Add content for depositing funds */}
            <Typography>Enter the amount to deposit:</Typography>
            <TextField fullWidth margin="normal" variant="outlined" label="Amount" />
            {/* Include form fields for deposit amount and any other necessary information */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDepositDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCloseDepositDialog} color="primary">
              Deposit
            </Button>
          </DialogActions>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <DialogContent>
            {/* Add content for withdrawing funds */}
            <Typography>Enter the amount to withdraw:</Typography>
            <TextField fullWidth margin="normal" variant="outlined" label="Amount" />
            {/* Include form fields for withdrawal amount and any other necessary information */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDepositDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCloseDepositDialog} color="primary">
              Withdraw
            </Button>
          </DialogActions>
        </TabPanel>
      </Dialog>
    </div>
  );
};

export default WalletBalance;
