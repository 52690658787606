import React, { createContext, useContext, useState } from 'react';

// Create the context
const SidebarContext = createContext();

// Create a custom hook for easy access to the context
export const useSidebar = () => useContext(SidebarContext);

// Provider component
export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Function to toggle the sidebar's open state
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // The value that will be supplied to any consuming components
  const value = {
    isSidebarOpen,
    toggleSidebar,
  };

  return (
    <SidebarContext.Provider value={value}>
      {children}
    </SidebarContext.Provider>
  );
};