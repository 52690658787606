import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSidebar } from '../components/layout/SidebarContext';
import { usePostHog } from 'posthog-js/react';

function Legal() {
  const posthog = usePostHog();
  posthog.capture('$pageview', {
        path: window.location.pathname
    });

  const { isSidebarOpen } = useSidebar();
  const theme = useTheme();

  return (
    <Box
      sx={{
        marginLeft: isSidebarOpen ? theme.sidebar.openWidth : theme.sidebar.closedWidth,
        maxHeight: `calc(100vh - ${theme.TopBar.height})`,
        padding: 3,
        display: 'flex',
        gap: 2,
        justifyContent: 'center',
        overflow: 'scroll',
      }}
    >
      <Paper elevation={3} sx={{ p: 5, height: '100%' }}>
        <Typography variant="h3" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography variant="h6" gutterBottom>
          This page contains important legal information about usage of this site. Please read it carefully.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          align="left"
          sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}
        >
          <strong>1. Introduction</strong> <br /> <hr />
          Welcome to ChessBets, a platform offering members the opportunity to engage in skill-based betting on chess
          games. By registering on ChessBets through any device, you agree to be bound by these Terms and Conditions,
          establishing a legal agreement between you and CHESSBETS LLC.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          align="left"
          sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}
        >
          <strong>2. ChessBets Statement</strong> <br /> <hr />
          2.1. Eligibility: Only individuals aged 21 or older can participate in betting activities on our platform. By
          accepting these terms, you confirm that you are at least 21 years old and that betting is legal in your
          jurisdiction. <br />
          2.2. Skill-Based Betting: All games on ChessBets are based on skill. The outcome of bets depends on the
          players' chess skills, not chance. <br />
          2.3. Account Creation: Users must register an account, providing accurate and truthful information. Each
          player is allowed only one account. <br />
          <strong>2.4. Excluded Territories</strong> <br /> <hr />
          a) Any country that is not United States of America (US); <br />
          b) Within the US the following States are excluded: <br />
          &nbsp;&nbsp;&nbsp;i. WASHINGTON <br />
          &nbsp;&nbsp;&nbsp;ii. NEW YORK <br />
          &nbsp;&nbsp;&nbsp;iii. NEVADA <br />
          &nbsp;&nbsp;&nbsp;iv. IDAHO <br />
          &nbsp;&nbsp;&nbsp;v. KENTUCKY <br />
          &nbsp;&nbsp;&nbsp;vi. MICHIGAN <br />
          &nbsp;&nbsp;&nbsp;vii. VERMONT <br />
          &nbsp;&nbsp;&nbsp;viii. Any other states or jurisdictions which, under the laws applicable to you, are legally
          precluded from playing the Games offered on the Platform, and any other jurisdiction ChessBets excludes, in
          its sole discretion, from time to time. <br />
          2.5. BY ACCEPTING THESE TERMS AND CONDITIONS, ACCESSING OR USING THE PLATFORM, CREATING A CUSTOMER ACCOUNT,
          AND/OR PLAYING THE GAMES, YOU SPECIFICALLY REPRESENT TO US THAT YOU DO NOT LIVE IN ANY OF THE EXCLUDED
          TERRITORIES. WE ARE SPECIFICALLY RELYING ON SUCH REPRESENTATIONS IN PROVIDING YOU ACCESS TO THE PLATFORM,
          CUSTOMER ACCOUNT, AND GAMES. IF YOU RESIDE IN ANY OF THE EXCLUDED TERRITORIES AND NONETHELESS CHECK THE BOX
          FOR ACCEPTANCE OF THESE TERMS AND CONDITIONS, ACCESS OR USE THE PLATFORM, CREATE A CUSTOMER ACCOUNT, AND/OR
          PLAY THE GAMES DESPITE OUR EFFORTS TO PREVENT YOU FROM DOING SO, WE CONSIDER YOUR ACTIONS TO BE A MATERIAL
          MISREPRESENTATION TO US, A FRAUDULENT INDUCEMENT OF OUR SERVICES, AND A VIOLATION OF THESE TERMS AND
          CONDITIONS, AND WE RESERVE ALL RIGHTS TO TAKE APPROPRIATE ACTION AGAINST YOU. <br />
          2.6. ONLY CUSTOMERS IN THE CONTINENTAL UNITED STATES AND HAWAII (EXCEPT FOR THE EXCLUDED TERRITORIES) ARE
          ELIGIBLE TO ACCESS AND USE THE PLATFORM, CREATE A CUSTOMER ACCOUNT, AND PLAY THE GAMES. <br />
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          align="left"
          sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}
        >
          <strong>3. User Conduct</strong> <br /> <hr />
          3.1. Users must engage in fair play, adhering to the rules of chess without any form of cheating or unfair
          advantage. <br />
          3.2. Betting must be conducted responsibly, within the limits of users' financial capabilities. <br />
          3.3. As a condition to access the Games or Platform, you may not, directly or indirectly: <br />
          a) hack into any part of the Games or Platform through password mining, phishing, or any other means; <br />
          b) attempt to modify, reverse engineer, or reverse-assemble any part of the Games or Platform; <br />
          c) knowingly introduce viruses, Trojans, worms, logic bombs, spyware, malware, or other similar material;{' '}
          <br />
          d) circumvent the structure, presentation or navigational function of any Game so as to obtain information
          that ChessBets has chosen not to make publicly available on the Platform; <br />
          e) engage in any form of cheating or collusion; <br />
          f) use the Platform and the systems of ChessBets to facilitate any type of illegal money transfer (including
          money laundering proceeds of crime); or <br />
          g) participate in or take advantage of, or encourage others to participate in or take advantage of schemes,
          organizations, agreements, or groups designed to share: <br />
          &nbsp;&nbsp;&nbsp;i. special offers or packages emailed to a specific set of Customers and redeemable by URL;
          or <br />
          &nbsp;&nbsp;&nbsp;ii. identification documents (including, but not limited to, photographs, bills and lease
          documents) for the purpose of misleading ChessBets as to a Customer’s identity. <br />
          3.4. You must not use the Platform for any unlawful or fraudulent activity or prohibited transaction
          (including Fraudulent Conduct) under the laws of any jurisdiction that applies to you. We monitor all
          transactions in order to prevent money laundering. <br />
          3.5. If ChessBets suspects that you may be engaging in, or have engaged in fraudulent, unlawful or improper
          activity, including money laundering activities or any conduct which violates these Terms and Conditions, your
          access to the Platform will be deactivated immediately and your Customer Account may be deactivated or
          suspended. If your Customer Account is deactivated or suspended under such circumstances, ChessBets is under
          no obligation to reverse any purchases you have made or to redeem any ChessBets Cash that may be in your
          Customer Account. In addition, ChessBets may pass any necessary information on to the relevant authorities,
          other online service providers, banks, credit card companies, electronic payment providers or other financial
          institutions. You will cooperate fully with any ChessBets investigation into such activity. <br />
          3.6. If you suspect any unlawful or fraudulent activity or prohibited transaction by another Customer, please
          notify us immediately via the means of communication listed in the Customer Complaints procedure.
        </Typography>

        <Typography
          variant="body1"
          gutterBottom
          align="left"
          sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}
        >
          <strong>4. Your Funds</strong> <br /> <hr />
          4.0 Your account must contain sufficient cleared funds for betting/staking. To place bets or engage in gaming,
          you must deposit funds into your account. See Deposits/Withdrawals for more information on funding and
          withdrawing from your account. <br />
          4.1 When you add funds to your ChessBets account, you instruct us to hold these funds, along with any
          winnings, exclusively for: <br />
          (i) Staking on your chess games. <br />
          (ii) Paying any fees or charges incurred through our service usage. We reserve the right to suspend or close
          your account if we suspect that you are depositing funds without intending to use them for chess game staking.
          Additionally, you must not maintain funds in your account beyond 183 days (6 months) without activity; failure
          to adhere will lead to temporary suspension or closure of the account, and we may notify relevant authorities.{' '}
          <br />
          4.2 We are entitled to any interest earned on the balances in your ChessBets account. <br />
          4.3 ChessBets does not provide credit, and bets must be covered by adequate funds in your account. We will
          void any bets/stakes accepted when your account lacks the necessary funds. If your account receives funds in
          error, it is your obligation to notify ChessBets promptly so we can correct the balance. <br />
          4.4 Immediate fund withdrawal after deposit is not permitted unless there are at least six betting/staking
          activities recorded in your account. Withdrawal requests under these conditions may be denied. <br />
          4.4.1 To combat fraud, at least 50% of any deposit over $100 must be utilized in gaming before withdrawal
          requests are approved. <br />
          4.5 Only the registered account holder can request withdrawals, which must be issued in their name. <br />
          4.6 Withdrawals can generally be processed through the 'Withdraw' option on the Website, provided your
          ChessBets account has enough funds. While there's no fixed daily maximum withdrawal limit, requests exceeding
          $500 might need special arrangements. <br />
          4.7 It is your responsibility to report your winnings and losses to the relevant tax and/or legal authorities
          in your jurisdiction. <br />
          4.8 You should not engage in internet gambling if it is prohibited in your location. Despite this, if you use
          your payment card for transactions in such jurisdictions, any placed bets/stakes will remain valid regardless
          of the outcome. <br />
        </Typography>

        <Typography
          variant="body1"
          gutterBottom
          align="left"
          sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}
        >
          <strong>5. Disruptions and Change</strong> <br /> <hr />
          5.1. No warranties: The Platform is provided on an “as is” basis and to the fullest extent permitted by law,
          we make no warranty or representation, whether express or implied, in relation to the satisfactory quality,
          fitness for purpose, completeness or accuracy of the Platform (including the Games and Content). <br />
          5.2. Malfunctions: <br />
          a) WE DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS TO THE SITES, THE PLATFORM, ANY OF YOUR
          ACCOUNT(S), OR THE GAMES WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY, OR ERROR-FREE. <br />
          b) ChessBets is not liable for any downtime, server disruptions, lagging, or any technical or political
          disturbance to Game play, nor attempts by you to Participate by methods, means or ways not intended by us.{' '}
          <br />
          c) ChessBets accepts no liability for any damages or losses which are deemed or alleged to have arisen out of
          or in connection with any Platform or its Content including, without limitation, delays or interruptions in
          operation or transmission, loss or corruption of data, communication or lines failure, any person’s misuse of
          a Platform or its Content or any errors or omissions in Content. <br />
          d) In the event of a Platform system malfunction all Game play on that Platform is void. <br />
          e) In the event a Game is started but fails to conclude because of a failure of the system, ChessBets will use
          commercially reasonable efforts to reinstate the amount of ChessBets Cash played in the Game to you by
          crediting it to your Customer Account. <br />
          f) ChessBets reserves the right to alter Customer balances and account details to correct such miChessBetss.{' '}
          <br />
          g) ChessBets reserves the right to remove any part of the Games from the Platform at any time. Any part of the
          Games that indicate incorrect behavior affecting Prize redemption, game data, balances, ChessBets Cash
          balances or other balances, that may be due to misconfiguration or a bug, will be cancelled and removed from
          the Platform. Customer balances and account details may be altered by ChessBets in such cases in order to
          correct any miChessBets. <br />
          5.3. Change: ChessBets reserves the right to suspend, modify, remove or add Content to the Platform at its
          sole discretion with immediate effect and without notice to you. We will not be liable to you for any loss
          suffered as a result of any changes made or for any modification or suspension of or discontinuance of the
          Platform (including any Game thereon) and you will have no claims against ChessBets in such regard. <br />
          5.4. Service Suspension: We may temporarily suspend the whole or any part of the Platform for any reason at
          our sole discretion. We may, but will not be obliged to, give you as much notice as is reasonably practicable
          of such suspension. We will restore the Platform, as soon as is reasonably practicable, after such temporary
          suspension. <br />
          5.5 Viruses: Although we take reasonable measures to ensure that the Platform is free from viruses we cannot
          and do not guarantee that the Platform is free of such problems. It is your responsibility to protect your
          systems and have in place the ability to reinstall any data or programs lost due to a virus.
        </Typography>

        <Typography
          variant="body1"
          gutterBottom
          align="left"
          sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}
        >
          <strong>6. Dispute Resolution</strong> <br /> <hr />
          6.1. Any disputes regarding game outcomes or bets should be directed to our support team for resolution.{' '}
          <br />
          6.2. ChessBets reserves the right to review game logs and bets to resolve disputes.
          <br />
          6.3. The decisions of ChessBets staff on disputes with respect to fair play are final.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          align="left"
          sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}
        >
          <strong>7. Legal Compliance</strong>
          <br /> <hr />
          7.1. Users must comply with all applicable laws and regulations regarding online betting and gaming. ChessBets
          does not assume liability for illegal use of the platform.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          align="left"
          sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}
        >
          <strong>8. Limitation of Liability</strong>
          <br /> <hr />
          8.1. ChessBets is not liable for losses incurred through bets, game outcomes, or technical issues.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          align="left"
          sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}
        >
          <strong>9. Amendments</strong>
          <br /> <hr />
          9.1. ChessBets reserves the right to modify these Terms and Conditions, which take effect upon publication.
        </Typography>

        <Typography
          variant="body1"
          gutterBottom
          align="left"
          sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}
        >
          <strong>10. OTHER</strong>
          <br /> <hr />
          10.1. Entire Agreement <br />
          These Terms and Conditions constitute the entire agreement between you and us with respect to your
          Participation and supersede all prior or contemporaneous communications and proposals, whether electronic,
          oral or written, between you and us with respect to your Participation. <br />
          10.2. Amendments <br />
          ChessBets reserves the right to amend these Terms and Conditions, or to implement or amend any procedures, at
          any time. Any amendments will be published on the Platform and such changes will be binding and effective
          immediately. If you do not agree to the amended Terms and Conditions, you must stop using the Platform. <br />
          10.3. Tax <br />
          You are solely responsible for any taxes which apply to any Prizes that you collect or redeem from your
          Participation. <br />
          10.4. Force Majeure <br />
          ChessBets will not be liable or responsible for any failure to perform, or delay in performance of, any of our
          obligations under these Terms and Conditions that is caused by events outside of our reasonable control.{' '}
          <br />
          10.5. No agency <br />
          Nothing in these Terms and Conditions will be construed as creating any agency, partnership, trust
          arrangement, fiduciary relationship or any other form of joint enterprise between you and us. <br />
          10.6. Severability <br />
          If any of the Terms and Conditions are determined by any competent authority to be invalid, unlawful or
          unenforceable to any extent, such term, condition or provision will, to that extent, be severed from these
          Terms and Conditions. All remaining terms, conditions and provisions will continue to be valid to the fullest
          extent permitted by law. In such cases, the part deemed invalid or unenforceable will be amended in a manner
          consistent with the applicable law to reflect, as closely as possible, ChessBets’s original intent. <br />
          10.7. Explanation of Terms and Conditions <br />
          We consider these Terms and Conditions to be open and fair. If you need any explanation regarding these Terms
          and Conditions or any other part of our Platform contact Customer Support via this form. <br />
          The Terms and Conditions prevail over any communication via email or chat. <br />
          All correspondence between you and us may be recorded. <br />
          10.8. Assignment <br />
          These Terms and Conditions are personal to you, and are not assignable, transferable or sub-licensable by you
          except with our prior written consent. We reserve the right to assign, transfer or delegate any of our rights
          and obligations hereunder to any third party without notice to you. <br />
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          align="left"
          sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}
        >
          <strong>11. Governing Law</strong>
          <br /> <hr />
          These Terms and Conditions, your use of the Platform and our entire relationship will be governed, and
          interpreted in accordance with, the laws of the State of Illinois in the United States, without regard for its
          choice of conflict of law principles. The application of the United Nations Convention on Contracts for the
          International Sale of Goods is specifically excluded.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          align="left"
          sx={{ border: 1, borderColor: 'primary.main', borderRadius: 1, p: 1 }}
        >
          <strong>12. Contact Information</strong>
          <br /> <hr />
          12.1. For any inquiries or disputes, contact <a href="mailto:chessbetsllc@gmail.com" style={{color: theme.palette.primary.main}}>chessbetsllc@gmail.com</a>.
        </Typography>
      </Paper>
    </Box>
  );
}

export default Legal;

