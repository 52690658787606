import React, { useState, useEffect } from "react";
import { Chess } from "chess.js"; // Corrected import statement for chess.js
import { Chessboard } from "react-chessboard";
import { useTheme } from "@emotion/react";
import { IconButton, Box, Typography } from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

// Sample PGNs of famous games with player names
const famousPGNs = [
  { pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Nb8 10. d4 Nbd7", players: "Capablanca vs. Alekhine" },
  { pgn: "1. e4 e6 2. d4 d5 3. Nd2 Nf6 4. e5 Nfd7 5. Bd3 c5 6. c3 Nc6 7. Ne2 cxd4 8. cxd4 f6 9. exf6 Nxf6 10. Nf3 Bd6", players: "Kasparov vs. Karpov" },
  { pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6 5. b3 Bb4+ 6. Bd2 Be7 7. Bg2 c6 8. O-O d5 9. Qc2 O-O 10. Bf4 c5", players: "Fischer vs. Spassky" }
];

function AutoChess({ boardSize, darkSquareColor, lightSquareColor, autoPlay }) {
  const [game, setGame] = useState(new Chess());
  const [isAutoPlay, setIsAutoPlay] = useState(autoPlay);
  const [pgnIndex, setPgnIndex] = useState(0); // Index to track the current PGN
  const [moveIndex, setMoveIndex] = useState(0); // Index to track the current move in the PGN
  const theme = useTheme();

  useEffect(() => {
    if (pgnIndex !== -1) {
      game.reset();
      setGame(new Chess());
      setMoveIndex(0);
    }
  }, [pgnIndex]);

  useEffect(() => {
    let autoPlayInterval;
    if (isAutoPlay && pgnIndex !== -1) {
      autoPlayInterval = setInterval(() => {
        const moves = famousPGNs[pgnIndex].pgn.split(' ').filter((_, index) => index % 3 !== 0);
        if (moveIndex < moves.length) {
          game.move(moves[moveIndex]);
          setGame(new Chess(game.fen()));
          setMoveIndex(moveIndex + 1);
        } else {
          setIsAutoPlay(false); // Stop autoplay if all moves are played
          // Move to the next PGN in a round-robin fashion
          const nextPgnIndex = (pgnIndex + 1) % famousPGNs.length;
          setPgnIndex(nextPgnIndex);
          game.reset();
          setGame(new Chess());
          setMoveIndex(0);
        }
      }, 1000); // Set interval for autoplay
    }
    return () => clearInterval(autoPlayInterval); // Clear interval on component unmount
  }, [isAutoPlay, moveIndex, game, pgnIndex]);

  const restartGame = () => {
    game.reset();
    setGame(new Chess());
    setMoveIndex(0);
    setIsAutoPlay(false);
  };

  const handlePgnChange = (direction) => {
    let newIndex = pgnIndex + direction;
    if (newIndex < 0) newIndex = famousPGNs.length - 1;
    else if (newIndex >= famousPGNs.length) newIndex = 0;
    setPgnIndex(newIndex);
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Chessboard
        id="AutoPlayChessboard"
        animationDuration={200}
        boardheight={"100%"}
        position={game.fen()}
        customDarkSquareStyle={{ backgroundColor: darkSquareColor }}
        customLightSquareStyle={{ backgroundColor: lightSquareColor }}
        customBoardStyle={{ borderRadius: '5px', boxShadow: theme.shadows[1] }}
        arePiecesDraggable={false}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '20px' }}>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <IconButton onClick={() => setIsAutoPlay(!isAutoPlay)} color="primary" aria-label="play/pause">
            {isAutoPlay ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
          <IconButton onClick={restartGame} color="secondary" aria-label="restart">
            <RestartAltIcon />
          </IconButton>
        </Box>
        <Box sx={{ padding: '10px', display: 'flex', alignItems: 'center', gap: '10px', background: `linear-gradient(160deg, ${theme.palette.secondary.light}, ${theme.palette.secondary.dark})`, boxShadow: theme.shadows[1], borderRadius: '10px' }}>
          <IconButton onClick={() => handlePgnChange(-1)}><ArrowBackIosNewIcon /></IconButton>
          <Typography variant="body1">{pgnIndex !== -1 ? famousPGNs[pgnIndex].players : "Random"}</Typography>
          <IconButton onClick={() => handlePgnChange(1)}><ArrowForwardIosIcon /></IconButton>
        </Box>
      </Box>
    </div>
  );
}

export default AutoChess;
