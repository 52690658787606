import React, { useState, useContext, createContext, useEffect, useRef } from 'react';
import { IconButton, TextField, Box, List, ListItem, Drawer, Button, Paper, Typography } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { getUserInfo } from '../../services/UserApi';
import { useAuth } from '../../services/AuthContext';

const ChatboxContext = createContext();

export const useChatbox = () => useContext(ChatboxContext);

const ChatboxProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChatbox = () => setIsOpen(!isOpen);

  return <ChatboxContext.Provider value={{ isOpen, toggleChatbox }}>{children}</ChatboxContext.Provider>;
};

// Example chatlog data for testing
const exampleChatlog = JSON.stringify([
  { sender: 'Alice', text: 'Hello, how are you?' },
  { sender: 'Bob', text: "I'm good, thanks! And you?" },
  { sender: 'Alice', text: 'Doing well, thanks for asking!' },
  { sender: 'Charlie', text: "Hey everyone, what's up?" },
]);

const Chatbox = ({ chatlog = exampleChatlog }) => {
  const { session } = useAuth();
  const { isOpen, toggleChatbox } = useChatbox();
  const [currentMessage, setCurrentMessage] = useState('');
  const [lastTimestamp, setLastTimestamp] = useState(null);
  const [messages, setMessages] = useState(JSON.parse(chatlog).reverse());
  const bottomListRef = useRef(null);
  const listRef = useRef();

  const handleSendMessage = async (event) => {
    event.preventDefault();
    const data = await getUserInfo(session.access_token);
    if (currentMessage.trim() !== '') {
      fetch(`${process.env.REACT_APP_SERVER_URL}/post_chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sender: data.username, timestamp: new Date().toISOString(), text: currentMessage }),
      });
      setMessages([...messages, { sender: data.username, timestamp: new Date().toISOString(), text: currentMessage }]);
      setCurrentMessage('');
    }
  };

  const getMessages = () => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/get_chat`, {
      method: 'GET',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.messages.length > 0) {
          setMessages(data.messages);
          setLastTimestamp(data.messages[data.messages.length - 1].timestamp);
        }
      })
      .catch((error) => console.error('Error fetching messages:', error));
  };

  // TODO: We can consider adding this back in if the server isn't getting fucked
  //   Note that right now chats will only sync on the component opening!
  //   useEffect(() => {
  //     const intervalId = setInterval(getMessages, 30000); // Fetch messages every 30 seconds

  //     return () => clearInterval(intervalId); // Cleanup interval on component unmount
  //   }, []);

  useEffect(() => {
    if (isOpen) {
      getMessages();
    }
  }, [isOpen]); // Rerun effect when isOpen changes

  useEffect(() => {
    // Scroll to bottom on initial load and whenever a new message is added
    scrollToBottom();
  }, [messages.length]); // Depend on messages.length for reactivity

  const scrollToBottom = () => {
    bottomListRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      {!isOpen && (
        <IconButton onClick={toggleChatbox} sx={{ position: 'fixed', bottom: 20, right: 20, zIndex: 1300 }}>
          <ChatIcon />
        </IconButton>
      )}
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={toggleChatbox}
        sx={{
          '& .MuiDrawer-paper': {
            width: '400px',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column-reverse',
          },
        }}
      >
        <Box component="form" onSubmit={handleSendMessage} sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type your message"
            value={currentMessage}
            onChange={(e) => setCurrentMessage(e.target.value)}
            sx={{ mr: 1 }}
          />
          <Button type="submit" variant="contained" color="primary">
            <SendIcon />
          </Button>
        </Box>
        <List ref={listRef} sx={{ width: '100%', overflowY: 'auto', flexGrow: 1 }}>
          {messages.reverse().map((message, index) => (
            <ListItem key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 1 }}>
              <Paper elevation={2} sx={{ width: '100%', p: 1 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'text.primary', mb: 0.5 }}>
                  {message.sender}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {message.text}
                </Typography>
              </Paper>
            </ListItem>
          ))}
          <div ref={bottomListRef} />
        </List>
        <IconButton onClick={toggleChatbox} sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1300 }}>
          <CloseIcon />
        </IconButton>
      </Drawer>
    </>
  );
};

export { Chatbox, ChatboxProvider };
