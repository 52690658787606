import React from 'react';
import { Box, Stack, Grid, Paper } from '@mui/material';
import { useSidebar } from '../components/layout/SidebarContext';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import PlayChess from '../components/common/playChess';
import AvatarBox from '../components/common/AvatarBox';
import ChessTimer from '../components/common/ChessTimer';
import { GameProvider } from '../contexts/GameContext';
import GameTabs from '../components/common/GameTabs';
import EndGamePopup from '../components/common/EndGamePopup';
import otherPepe from '../assets/images/imrs.avif';
import pepe from '../assets/images/pepe.jpeg';
import { usePostHog } from 'posthog-js/react';

function Play() {
  const posthog = usePostHog();
  posthog.capture('$pageview', {
        path: window.location.pathname
    });

  const { isSidebarOpen } = useSidebar();
    const theme = useTheme();

  return (
    <div
      style={{
        height: `calc(100vh - ${theme.TopBar.height})`,
        marginLeft: isSidebarOpen ? theme.sidebar.openWidth : theme.sidebar.closedWidth,
        transition: 'margin-left 0.3s ease-in-out',
      }}
    >
      <GameProvider>
        <EndGamePopup />
        <Box
          sx={{
            height: '100%',
            marginRight: theme.PagePaperMargins.margin,
            marginLeft: theme.PagePaperMargins.margin,
            flexGrow: 1,
            flexShrink: 1,
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            justifyContent: 'center',
          }}
        >
          <Grid container spacing={4} sx={{ maxHeight: '100%' }}>
            <Grid item xs={12} md={6}>
              <Box sx={{ alignContent: 'center', display: 'flex', flexDirection: 'column', m: 2 }}>
                <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-around' }}>
                  <AvatarBox avatarSrc={otherPepe} userBox={false} />
                  <ChessTimer userOrOpponent={false} />
                </Stack>

                <PlayChess
                  customDarkSquareStyle={theme.defaultBoard.darkSquare}
                  customLightSquareStyle={theme.defaultBoard.lightSquare}
                ></PlayChess>

                <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-around' }}>
                  <AvatarBox avatarSrc={pepe} userBox={true} />
                  <ChessTimer userOrOpponent={true} />
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <GameTabs></GameTabs>
            </Grid>
          </Grid>
        </Box>
      </GameProvider>
    </div>
  );
}

export default Play;
