// userService.js
const getUserInfo = async (authToken) => {
    if (!authToken) {
        throw new Error('Auth token is not available');
    }

    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user/info`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        },
    });
    if (!response.ok) {
        throw new Error('Failed to fetch user info');
    }
    
    const userInfo = await response.json();
    console.log(userInfo);
    return userInfo;
};

const postUserRegistration = async (userInfo) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: userInfo.email,
            password: userInfo.password,
            options: {
                data: {
                    name: userInfo.name,
                    username: userInfo.username,
                    dob: userInfo.dob,
                    nationality: userInfo.nationality,
                    elo_rating: userInfo.elo_rating
                }
            }
        }),
    });
    if (!response.ok) {
        throw new Error('Failed to post user registration');
    }

    const result = await response.json();
    console.log(result);
    return result;
};

const getUserGames = async (authToken) => {
    if (!authToken) {
        throw new Error('Auth token is not available');
    }

    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user/games`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        },
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch user games: ${errorText}`);
    }
    
    const games = await response.json();
    console.log(games);
    return games;
};


export { getUserInfo, postUserRegistration, getUserGames };
